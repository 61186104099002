// https://css-tricks.com/building-progress-ring-quickly/

<template>
    <div 
        id="get-vs-button-container"
        class="d-flex progress-circle"
        v-if="startBreathing"
    >
            <div class="my-auto">
                <svg
                    class="circle"
                    :height="radius * 2 * radiusMultiplier"
                    :width="radius * 2 * radiusMultiplier"
                >
                    <circle class="progress-background"
                        stroke="#A5915C"
                        fill="transparent"
                        :stroke-width="normalizedStroke"
                        :r="normalizedRadius"
                        :cx="radius*radiusMultiplier"
                        :cy="radius*radiusMultiplier"
                    />
                    <circle class="progress"
                        stroke="#A5915C"
                        fill="transparent"
                        :stroke-dasharray="circumference + ' ' + circumference"
                        :style="{ strokeDashoffset }"
                        :stroke-width="normalizedStroke"
                        :r="normalizedRadius"
                        :cx="radius*radiusMultiplier"
                        :cy="radius*radiusMultiplier"
                    />
                    <text x="50%" y="50%" text-anchor="middle" dy=".3em">%</text>
                    <!-- <circle class="breathing-circle-animation" 
                        :r="normalizedRadius"
                        :cx="radius"
                        :cy="radius"
                        stroke="white"
                        fill="white"
                    /> -->
                </svg>
            </div>
            <div class="description">
                <p>
                    {{t('message.scanning_progress.title')}}
                </p>
                <ul>
                    <li v-for="text in $tm('message.scanning_progress.content')" :key="text">
                        {{text}}
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import VitalsConfig from '../../plugin/plugin_configuration.js'
import { ref } from "vue";

export default {
    setup() {
        const {t, locale} = useI18n();
        return {
            t,
            locale,
            progress: ref(0),
        }
    },
    props: {
        radius: Number,
        stroke: Number,
        timeLeft: {type: Number, default: null},
        text: String,
    },
    data() {
        var normalizedRadius = this.radius*3-this.stroke;
        const circumference = normalizedRadius * 2 * Math.PI;

        return {
            startBreathing: false,
            normalizedStroke: this.stroke,
            normalizedRadius,
            circumference,
            width: window.innerWidth,
            radiusMultiplier: 1,
        };
    },
    methods: {
        getWidth() {
            this.width = window.innerWidth;
            if (this.width < 2000 && this.width >= 1024) {
                this.normalizedStroke = 15;
                this.radiusMultiplier = 1.25;
            } else if (this.width >= 2000) { 
                this.normalizedStroke = 25;
                this.radiusMultiplier = 2.5
            } else if (this.width < 1024) {
                this.normalizedStroke = 10;
                this.radiusMultiplier = 1;
            }
            this.normalizedRadius = this.radius*this.radiusMultiplier-this.normalizedStroke;
            this.circumference = this.normalizedRadius * 2 * Math.PI;
        },
    },
    created() {
        this.totalTime = VitalsConfig.Plugin.scanTime;
    },
    mounted() {
        this.getWidth();
        window.addEventListener('resize', this.getWidth);
    },
    unmounted() {
        window.removeEventListener('resize', this.getWidth);
    },
    computed: {
        strokeDashoffset() {
            return this.circumference - this.progress / 100 * this.circumference;
        },
        inOutTextStyle() {
            var _in = this.$t('message.breath_in');
            var _out = this.$t('message.breath_out');
            return `--in:"${_in}"; --out:"${_out}";`;
        }
    },
    watch: {
        timeLeft(newValue, oldValue) {
            if(oldValue == null && newValue !== null) {
                this.startBreathing = true;
            }
            if (newValue === null) {
                this.progress = 0;
                this.state = "idle";
                this.startBreathing = false;
            } else {
                this.progress = Math.round(((this.totalTime - this.timeLeft) / this.totalTime)*100.0)
            }
        },
    }
}
</script>


<style scoped>
#get-vs-button-container {
    position: absolute;
    top: 20%;
    left: 5%;
    width: 80%;
    background-color: #FFFFFFB2;
    padding: 80px;
    border-radius: 36px;
}
.d-flex {
    gap: 80px;
}
.description {
    font-size: 45px;
    font-family: "Montserrat";
}
.progress {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}
.container {
    display: flex;
}
.text {
    content: v-bind(inOutTextStyle);
    position: absolute;
    color: black;
    top: 33%;
    left: 160px;
}
text {
    font-size: 80px;
}
/* p {
    position: relative;
    left: -50%;
    transform: translate(0%, -50%);
    font-size: 1.5em;
    color: white;
} */
.progress-background {
    opacity: 0.1;
}

@keyframes textAnimation {
    0% {opacity: 1.0; font-size: 1.2em; content: var(--in);}
    45% {opacity: 1.0; font-size: 2.9em; content: var(--in);}
    46% {opacity: 1.0; font-size: 2.9em; content: var(--out);}
    90% {opacity: 1.0; font-size: 1.2em; content: var(--out);}
    91% {opacity: 1.0; font-size: 1.2em; content: var(--in);}
    100% {opacity: 1.0; font-size: 1.2em; content: var(--in);}
}
@keyframes textAnimation-mobile {
    0% {opacity: 1.0; font-size: 1em; content: var(--in);}
    45% {opacity: 1.0; font-size: 1.5em; content: var(--in);}
    46% {opacity: 1.0; font-size: 1.5em; content: var(--out);}
    90% {opacity: 1.0; font-size: 1em; content: var(--out);}
    91% {opacity: 1.0; font-size: 1em; content: var(--in);}
    100% {opacity: 1.0; font-size: 1em; content: var(--in);}
}
@keyframes circleAnimation {
    0% {opacity: .75; fill: #ffaa00; transform: scale(0.4); }
    50% {opacity: .9; fill: #00cc00; transform: scale(1);}
    100% {opacity: .75; fill: #ffaa00; transform: scale(0.4);}
}
@keyframes circleAnimation-mobile {
    0% {opacity: .75; fill: #ffaa00; transform: scale(0.3); }
    50% {opacity: .9; fill: #00cc00; transform: scale(0.9);}
    100% {opacity: .75; fill: #ffaa00; transform: scale(0.3);}
}

.breathing-circle-animation {
    opacity: 1;
    position: relative;
    left: 50%;
    top: 50%;
    color: white;
}

@media (max-width: 2000px) {
    .d-flex {
        gap: 10px;
    }
    .description {
        font-size: 18px;
    }
    text {
        font-size:  40px;
    }
    #get-vs-button-container {
        width: 90%;
        padding: 20px;
        top: 200px;
    }
}

@media (max-width: 1024px) {
    #get-vs-button-container {
        top: 160px;
    }
}
</style>