<template>
    <div class="qr-container" v-if="showQr">
      <button type="button" class="back-button btn w-100 text-start" @click="back">
        &#60; {{t('scan_result.back')}}
      </button>
      <div class="w-100 d-flex justify-content-center">
        <qrcode-vue :value="url" :size="qrSize" :render-as="'svg'" class="qr"/>
      </div>
      <div class="loading-qr" v-html="t('scan_result.qr')">
      </div>
    </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import {useI18n} from "vue-i18n";
export default {
  setup() {
    const {t} = useI18n();
    return {
      t
    }
  },
  emits: ['updateShowRecommendation'],
  data() {
    return {
      qrSize: 240,
    }
  },
  props:{
    url: String,
    showQr: Boolean
  },
  components: {
    QrcodeVue,
  },
  methods: {
    getQRSize() {
        const width = window.innerWidth;
        if (width < 2000) {
            this.qrSize = 150;
        } else {
            this.qrSize = 240;
        }
    },
    back() {
      this.$emit('updateShowRecommendation', false);
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.getQRSize);
  },
  mounted() {
    this.getQRSize();
    window.addEventListener('resize', this.getQRSize);
  }
}
</script>

<style scoped>
.qr-container {
  background: rgba(165, 145, 92, 0.9);
  padding: 40px;
  gap: 24px;
  border-radius: 36px;
  margin-bottom: 40px;
}
.loading-qr {
  margin-top: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
}
.qr {
  padding: 5px;
  background-color: #FFFFFF;
}

.back-button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.231689px;
  color: #ffffff;
  padding: 0;
}
</style>