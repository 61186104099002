<template>
    <div v-if="iconPath && routerPath">
        <router-link :to="routerPath">
            <img :src="require('./assets/' + iconPath)" :class="iconStatus"/>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "sidebar-icon",
    props: {
        inputIconPath: { type: String, default: undefined },
        selected: { type: Boolean, default: false },
        routerPath: { type: String, default: undefined },
    },
    computed: {
        iconStatus() {
            let selectedMobile = [this.selected, this.isMobile()].join('-');
            switch (selectedMobile) {
                case 'false-false':
                    return "button-icon";
                case 'false-true':
                    return "button-icon-mobile";
                case 'true-false':
                    return "button-icon-selected";
                case 'true-true':
                    return "button-icon-selected-mobile";
                default:
                    return "button-icon";
            }
        },
        iconPath() {
            if (this.isMobile() && this.selected && this.inputIconPath) {
                return this.inputIconPath.replace('.svg', '_mobile.svg');
            } else {
                return this.inputIconPath;
            }
        },
    },
    methods: {
        isMobile() {
            return window.innerWidth <= 768
        },
    },
}
</script>

<style scoped>
.button-icon {
    width: 50px;
    height: 50px;
    transform: translateX(3px);
}
.button-icon-mobile {
    width: 40px;
    height: 40px;
    transform: translateY(-2px);
}
.button-icon-selected {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    transform: translateX(-13px);
}
.button-icon-selected-mobile {
    width: 50px;
    height: 50px;
    transform: translateY(2px);
}

</style>