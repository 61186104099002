<template>
  <div class="container">
    <div class="condition-list">
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.brightness" />
        {{ t("message.conditions.lighting") }}
      </p>
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.distance" />
        {{ t("message.conditions.distance") }}
      </p>
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.centered" />
        {{ t("message.conditions.centered") }}
      </p>
    </div>
    <div class="condition-list">
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.movement" />
        {{ t("message.conditions.movement") }}
      </p>
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.serverReady" />
        {{ t("message.conditions.server_ready") }}
      </p>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import ConditionIcon from "./condition-icon.vue";

export default {
  name: "conditions-hud",
  props: ["conditions"],
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  computed: {
    isOK() {
      return Object.values(this.conditions).reduce((previousValue, currentValue) => {
        return previousValue && currentValue;
      }, true);
    },
  },
  components: {
    ConditionIcon,
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.condition {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0;
  gap: 5px;
}
.condition-list {
  line-height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}
.icon {
  margin-left: 0em;
}
@media (max-width: 2000px) {
  .condition {
    font-size: 16px;
  }
  .condition-list {
    line-height: 45px;
  }
}
</style>
