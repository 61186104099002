<template>
  <div class="card-container justify-content-center">
    <div class="header w-100 m-0">
      <div class="d-flex align-items-center">
        <div class="">
          <div class="logo">
            <p class="m-0 p-0">{{ number + 1 }}/3</p>
          </div>
        </div>
        <div class="">
          <div class="question">
            {{ t("quiz[" + number + "].question") }}
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100 m-0 justify-content-center options">
      <div
        :class="`${$tm('quiz[' + number + '].options').length % 2 == 0 ? 'col-6' : 'col-4'} p-3 option-container`"
        v-for="(option, index) in $tm('quiz[' + number + '].options')"
        :key="index"
      >
        <div
          class="option"
          @click="
            nextQuestion();
            addResult(index);
          "
        >
          {{ t("quiz[" + number + "].options[" + index + "]") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  props: {
    number: Number,
    last: Boolean,
    result: Array,
  },
  emits: ["nextQuestion", "addResult"],
  methods: {
    nextQuestion() {
      setTimeout(() => {
        this.$emit("nextQuestion");
      }, 5);
    },
    addResult(number) {
      this.$emit("addResult", this.result[number]);
    },
  },
};
</script>

<style scoped>
.card-container {
  width: 75%;
}
.header {
  background-color: rgba(165, 145, 92, 0.9);
  border-radius: 36px 36px 0px 0px;
  padding: 80px;
  height: 300px;
}
.title {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-align: left;
  color: white;
}
.subtitle {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  opacity: 0.5;
}
.question {
  padding-left: 30px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  letter-spacing: 0.25px;
  color: white;
}
.option {
  background: #333d47;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
  border-radius: 240px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  letter-spacing: 0.231689px;
  color: #ffffff;
  padding: 45px;
  cursor: pointer;
  min-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.option:active {
  background: #848a90;
}
.logo {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  letter-spacing: 0.25px;
  color: white;
  border: 1px solid #ffffff;
  border-radius: 100px;
  margin: 0px;
  padding: 20px;
}

.logo-image {
  width: 48px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}
.next-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 5px 0;
  color: #5856d7;
}
.small {
  width: 500px;
  background-color: white;
}
.big {
  width: 982px;
}
.content {
  height: 58px;
}
.next-button {
  cursor: pointer;
}
.option-container {
  position: relative;
}
.options {
  border-radius: 0px 0px 36px 36px;
  background-color: white;
  padding: 120px 80px;
}
@media (max-width: 2000px) {
  .logo {
    font-size: 24px;
  }
  .header {
    margin: 25px 10px;
    padding: 50px;
    height: auto;
  }
  .header > .row > .col-1 {
    padding: 0 3px;
  }
  .small {
    width: 611px;
  }

  .options {
    padding: 30px 20px;
  }

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 24px;
  }

  .question {
    font-size: 24px;
  }

  .option {
    font-size: 16px;
    padding: 20px 35px;
    min-height: auto;
  }

  .next-text {
    font-size: 24px;
  }
  .card-container {
    width: 90%;
  }
}
</style>
