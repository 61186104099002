<template>
    <div class="bg-light content-card my-5 justify-content-center">
        <div class="px-5 py-2 bg-green">
            <h2 class="text-light fw-bolder px-0 align-middle" :style="{'font-size': '40px', 'line-height': '95px'}">{{t('health_report.' + type + '.title')}}</h2>
        </div>
        <div v-for="vital in vitalSign" :key="vital">
            <vitals :vital="vital" :info="info" :type="type"/>
        </div>
    </div>
</template>

<script>
import Vitals from "./vitals.vue"
import { useI18n } from 'vue-i18n';
export default {
    setup() {
        const { t, locale } = useI18n();
        return {
            t, locale
        }
    },
    props: {
        vitalSign: Array,
        info: Object,
        type: String,
    },
    components: {
        Vitals
    }
}
</script>

<style scoped>

.content-card {
    border-radius: 40px;
    /* box-shadow: 4px 4px 4px 0px #00000040; */
}

.bg-green {
    background: #A5915C;
    border-radius: 40px 40px 0px 0px;
}

</style>