<template>
  <div class="start-content d-flex flex-column justify-content-center">
    <div class="header w-100 m-0">
      <div v-if="!showTC">
        <p class="title m-0">
          {{ t("start_content.title") }}
        </p>
        <div class="text-center w-100 start-icon-container">
          <img src="./assets/start-icon.svg" alt="" class="start-icon" />
        </div>
        <p class="subtitle" v-html="t('start_content.subtitle')"></p>
      </div>

      <div v-else>
        <button type="button" class="back-button btn w-100 text-start" @click="updateShowTC(false)">
          &#60; {{ t("button.back_to_homepage") }}
        </button>
        <div class="title">
          {{ t("start_content.terms_condition.title") }}
        </div>
      </div>
    </div>

    <div class="description">
      <div v-if="!showTC" class="steps">
        <div class="d-flex steps-container">
          <div class="col align-items-center" v-for="(step, index) in $tm('start_content.steps')" :key="index">
            <div class="number">{{ index + 1 }}</div>
            <div v-html="step"></div>
          </div>
        </div>
        <div class="warning d-flex align-items-center">
          <img src="./assets/warning.svg" alt="" class="warning-icon" />
          <p class="m-0 h-100">
            {{ t("start_content.warning") }}
          </p>
        </div>
      </div>
      <div v-else>
        <ol class="content text-start">
          <li v-for="content in $tm('start_content.terms_condition.content')" :key="content">
            {{ content }}
          </li>
        </ol>
      </div>
    </div>
    <div class="text-center w-100">
      <button
        v-if="!showTC"
        type="button"
        class="btn continue-button mx-auto"
        :disabled="!enable"
        @click="updateShowTC(true)"
      >
        {{ t("button.immediately") }}
      </button>
      <div v-else class="">
        <vital-sign-button
          class="continue-button mx-2"
          :enable="enable"
          @startMeasurement="$emit('startMeasurement')"
          @removeProfile="removeProfile"
          @updateModal="updateModal"
        />
        <button type="button" class="btn continue-button mx-2" @click="updateShowTC(false)">
          {{ t("button.disagree") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { inject } from "vue";
import vitalSignButton from "./vital-sign-button-js.vue";

export default {
  setup() {
    const { t, locale } = useI18n();
    const { profileFilled, updateProfileFilled } = inject("profileFilled");

    return {
      profileFilled,
      updateProfileFilled,
      t,
      locale,
    };
  },
  props: {
    vsStatus: String,
    showTC: Boolean,
    enable: Object,
  },
  emits: ["updateShowTC", "startMeasurement", "removeProfile", "updateModal"],
  computed: {
    startCountdown() {
      return this.conditions ? Object.values(this.conditions).every((item) => item) : false;
    },
    disabled() {
      return false;
    },
  },
  methods: {
    updateShowTC(val) {
      this.$emit("updateShowTC", val);
    },
    startMeasurement() {
      this.$emit("startMeasurement");
    },
    removeProfile() {
      this.$emit("removeProfile");
    },
    updateModal(val) {
      this.$emit("updateModal", val);
    },
  },
  components: {
    vitalSignButton,
  },
  watch: {
    profileFilled(val) {
      if (val) {
        this.$emit("startMeasurement");
        this.state = "measuring";
      }
    },
  },
};
</script>

<style scoped>
div::-webkit-scrollbar {
  display: none;
}

.back-button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.231689px;
  color: #ffffff;
  padding: 0;
}
.continue-button {
  background: #333d47;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
  border-radius: 240px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  color: white;
  text-align: center;
  letter-spacing: 0.231689px;
  height: 240px;
  position: relative;
  top: -120px;
}
.warning-icon {
  width: 32px;
}
.warning {
  margin-top: 24px;
  padding: 18px 48px 18px 30px;
  text-align: left;
  font-weight: 400;
  background-color: rgba(238, 239, 240, 1);
  font-size: 14px;
  gap: 15px;
}
.steps-container {
  gap: 40px;
}
.start-icon {
  width: 124px;
}
.start-icon-container {
  padding-top: 48px;
  padding-bottom: 24px;
}
.description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  letter-spacing: 0.25px;
  color: #333d47;
  text-align: center;
  padding: 80px 123px 115px 123px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  border-radius: 0px 0px 36px 36px;
}
.header {
  background-color: rgba(165, 145, 92, 0.9);
  border-radius: 24px 24px 0px 0px;
  padding: 120px 80px;
}
.number {
  display: inline-block;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #333d47;
  width: 90px;
  height: 90px;
  border: 1px solid #333d47;
  border-radius: 56px;
  margin: 15px 40px;
}

.message {
  margin-top: 30px;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: white;
  font-size: 24px;
}
.opacity {
  opacity: 0.9;
}

.question {
  padding-top: 120px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}
.start-content {
  position: absolute;
  top: 20%;
  left: 10%;
  width: 80%;
  max-height: 76vh;
  border-radius: 24px;
  overflow: scroll;
}
.subtitle {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  color: white;
}
.title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 56px;
  text-align: center;
  color: white;
}
.vital-sign {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.25px;
  color: white;
  padding-top: 44px;
}

.description .title {
  font-weight: 600;
  font-size: 24px;
  color: black;
  margin-bottom: 24px;
}

.description .content {
  font-weight: 300;
  font-size: 14px;
  line-height: 160%;
  color: #333d47;
  text-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 8px 28px rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;
  padding-left: 20px;
}

@media (max-width: 2000px) {
  .continue-button {
    font-size: 24px;
    height: 90px;
    top: -45px;
    padding: 0 30px;
  }
  .description {
    font-size: 16px;
    padding: 40px;
    padding-bottom: 60px;
  }
  .header {
    padding: 30px 30px;
  }
  .question {
    padding-top: 30px;
  }
  img {
    width: 64px;
  }
  .number {
    font-size: 24px;
    line-height: 45px;
    height: 45px;
    width: 45px;
  }
  .start-content {
    width: 90%;
    left: 5%;
    top: 150px;
  }
  .subtitle {
    font-size: 16px;
  }
  .title {
    font-size: 32px;
  }
  .vital-sign {
    font-size: 24px;
    padding-top: 24px;
  }
}

@media (max-width: 940px) {
  .start-content {
    top: 120px;
  }
}

@media screen and (width: 2160px) and (height: 3180) {
  .start-content {
    top: 25%;
    left: 12.5%;
    width: 75%;
  }
}
</style>
