<template>
    <div class="recommendation mb-5">
      <button type="button" class="back-button btn text-dark" @click="back" v-if="!showQr">
        &#60; {{t('scan_result.back')}}
      </button>
      <div class="row rec">
        <div class="rec-title d-flex">
          <div class="rec-logo">
            <img src="../assets/lify.svg" alt="" class="" />
          </div>
          <div class="rec-title-text w-100 flex-grow">
            <p class="title">
              {{t('scan_result.lify_recommendation')}} :
            </p>
          </div>
        </div>
        <div class="rec-rec">
          <div class="result-box justify-content-center">
            <div class="p-0" ref="rec_box">
              <recommendation-box  v-for="(tea, index) of result.lify" :key="index" :type="'lify'" :content="tea" :index="index" v-show="index === page"/>
            </div>
            <div class="d-flex justify-content-between navigation">
              <button :disabled="page == 0 ? true : false" @click="changePage(0)" class="btn btn-link p-0">
                <img src="../assets/previous.svg" alt="">
              </button>
              <button :disabled="page == 1 ? true : false" @click="changePage(1)" class="btn btn-link p-0">
                <img src="../assets/next.svg" alt="">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <button 
      type="button" 
      class="btn continue-button" 
      @click="newScan">
      {{t('button.start_again')}}
    </button> -->
</template>

<script>
import {useI18n} from 'vue-i18n';
import RecommendationBox from "./recommendation-box.vue";

export default {
  setup() {
    const {t} = useI18n();
    return {
      t
    }
  },
  props: {
    showRecommendation: Boolean,
    result: Object,
    showQr: Boolean
  },
  emits: ["removeProfile", "updateShowRecommendation"],
  components: {
    RecommendationBox,
  },
  methods: {
    back() {
      this.$emit("updateShowRecommendation", false);
    },
    newScan() {
      this.back();
      this.$emit("removeProfile");
    },
    getQRSize() {
      const width = window.innerWidth;
      if (width < 2000) {
        this.qrSize = 150;
      } else {
        this.qrSize = 240;
      }
    },
    changePage(value) {
      this.page = value;
    },
    handleGesture() {
      if (this.touchendX - this.touchstartX < -175 ) {
          this.page = 1;
      }
      if (this.touchendX - this.touchstartX > 175) {
          this.page = 0;
      }
    }
  },
  data() {
    return {
      page: 0
    }
  },
  computed: {
    landscape() {
      if (window.innerWidth > 1024) {
        return true;
      } else {
        return false;
      }
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.getQRSize);
  },
  mounted() {
    const touchableElement = this.$refs.rec_box

    touchableElement.addEventListener('touchstart', (event) => {
      this.touchstartX = event.changedTouches[0].screenX;
      this.touchstartY = event.changedTouches[0].screenY;
    }, false);

    touchableElement.addEventListener('touchend', (event) => {
      this.touchendX = event.changedTouches[0].screenX;
      this.touchendY = event.changedTouches[0].screenY;
      this.handleGesture();
    }, false);

    this.getQRSize();
    window.addEventListener("resize", this.getQRSize);
  },
};
</script>

<style scoped>
#result {
  display: flex;
  justify-content: center;
}
.result-container {
  position: absolute;
  max-height: 80vh;
  top: 450px;
  overflow: scroll;
  width: 74%;
}
.content-title {
  font-family: Montserrat;
  font-size: x-large;
  padding-top: 60px;
  padding-bottom: 60px;
}
.center-item {
  display: grid;
  justify-content: center;
}
.body-container {
  height: 100%;
  width: 100% !important;
  margin: 0;
}
.subscribe-container {
  margin-top: 60px;
}
.subscribe {
  box-sizing: border-box;
  font-family: "Montserrat";
  display: inline-block;
  width: 636px;
  height: 268px;
  background: #fe805c;
  border-radius: 24px;
  padding: 0 30px;
}
.subscribe-title {
  margin-bottom: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;

  color: #ffffff;
}
.subscribe-subtitle {
  margin-bottom: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;
}
.subscribe-form {
  border-radius: 90px 0 0 90px;
}
.subscribe-input {
  border-radius: 90px 0 0 90px;
  border-bottom: none;
  border-left: none;
  border-top: none;
}
.subscribe-button {
  height: 51px;
  background-color: white;
  border-radius: 0px 90px 90px 0;
  border-left: #12121f;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;

  color: #12121f;
}
.subscribe-col {
  padding: 26px;
}
.contact-info {
  margin-top: 22.4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;
}
.back-button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.231689px;
  color: #ffffff;
  padding-bottom: 50px;
}
.barcode {
  padding-top: 26px;
}
.barcode-text {
  padding-top: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: left;

  color: #ffffff;
}
.recommendation {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0 0 40px 40px;
  padding: 40px;
  padding-bottom: 70px;
}
.survey {
  margin: auto;
}
.survey-header {
  background: rgba(165, 145, 92, 0.9);
  border-radius: 36px;
  padding: 80px 80px 120px 80px;
}
.survey-body {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0px 0px 36px 36px;
  padding: 80px;
  gap: 80px;
}
.header-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 98px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
}
.header-subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
}
.body-text {
  display: inline-block;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.25px;
  color: #333d47;
  width: 1116px;
}
.body-qr {
  float: right;
  width: 240px;
}
.rec {
  display: flex;
  margin: auto;
}
.rec-title {
  gap: 36px;
  padding: 0;
  margin-bottom: 24px;
  align-items: center;
}
.rec-rec {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 24px;
  padding: 0;
}
.rec-logo img {
  width: 124px;
}
.rec-title-text {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 36px;
  font-weight: 500;
  line-height: 132%;
  letter-spacing: 0.1971px;
  color: #12121f;
}
.rec-title-text .subtitle {
  font-style: italic;
  font-size: 36px;
}

.continue-button {
    background: #333D47;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
    border-radius: 240px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    color: white;
    text-align: center;
    letter-spacing: 0.231689px;
    height: 240px;
    width: 480px;
    position: relative;
    top: -120px;
}

.qr-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #5699c1;
  padding-top: 18px;
}
.navigation {
  padding: 10px 56px 26px 56px;
}

@media (max-width: 2000px) {
  #result {
    padding: 0;
    margin-bottom: 50px;
  }
  .result-container {
    position: relative;
    top: 0;
    width: 100%;
  }
  .back-button {
    font-size: 18px;
    padding-bottom: 30px;
  }
  .survey-header {
    padding: 40px;
  }
  .header-title {
    font-size: 48px;
    line-height: 58px;
  }
  .header-subtitle {
    font-size: 16px;
    line-height: 19px;
  }
  .body-text {
    font-size: 16px;
    line-height: 19px;
  }
  .qr-text {
    font-size: 16px;
    line-height: 19px;
  }
  .survey-body {
    padding: 40px;
  }
  .body-qr {
    width: 150px;
  }    
  .continue-button {
      font-size: 24px;
      height: 90px;
      width: 200px;
      top: -45px;
  }
  .rec-title-text .title { 
    margin-bottom: 7px;
  }
  .rec-logo > img {
      width: 70px;
      height: 70px;
  }
  .rec-title-text {
    font-size: 24px;
  }
  .rec-title-text .subtitle {
    font-size: 16px;
    margin-bottom: 0;
  }
}
</style>