<template>
    <div class="start-content-container justify-content-center">
        <qr-code 
            v-if="showRecommendation"
            :url="url" 
            :showQr="showQr"
            @updateShowRecommendation="updateShowRecommendation"
        />
        <email-input 
            :showRecommendation="showRecommendation" 
            :vitalSign="vitalSign" 
            :showQr="showQr"
            :scanResultId="scanResultId"
            @updateShowRecommendation="updateShowRecommendation"
            @updateShowQr="updateShowQr"
            @removeProfile="removeProfile"
        />
        <recommendation 
            v-if="showQr"
            :showRecommendation="showRecommendation" 
            :result="recommend" 
            :showQr="showQr"
            @updateShowRecommendation="updateShowRecommendation" 
            @removeProfile="removeProfile"
        />   
    </div>
</template>

<script>
import CryptoJS from "crypto-js"
import Recommendation from "./recommendation.vue";
import QrCode from "./qr-code.vue";
// import QrcodeVue from "qrcode.vue";
import EmailInput from "./email-input.vue"
import {useI18n} from "vue-i18n";

export default {
    setup() {
        const {locale} = useI18n();
        return {
            locale
        }
    },
    props: {
        vitalSign: Object,
        userProfile: Object,
        recommend: Object,
        scanResultId: String
    },
    components: {
        // QrcodeVue,
        EmailInput,
        Recommendation,
        QrCode
    },
    emits: ['removeProfile'],
    data() {
        return {
            url: "",
            qrSize: 240,
            showRecommendation: false,
            showQr: true,
        }
    },
    methods: {
        hideQR() {
            this.$emit('updateShowRecommendation', true);
        },
        getQRSize() {
            const width = window.innerWidth;
            if (width < 2000) {
                this.qrSize = 150;
            } else {
                this.qrSize = 240;
            }
        },
        updateShowRecommendation(value) {
            this.showRecommendation = value;
        },
        removeProfile() {
            this.$emit('removeProfile');
        },
        updateShowQr(value) {
            this.showQr = value;
        },
        generateQrLink() {
            const gender = this.userProfile.gender,
                vs = this.vitalSign,
                rec = this.recommend;
            const queryObj = {
                gender: gender,
                heartRate: vs.heartRate,
                stress: vs.stress,
                generalWellness: vs.generalWellness,
                facialSkinAge: vs.facialSkinAge,
                spo2: vs.spo2,
                lify: rec.lify,
                language: this.$i18n.locale
            }
            const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(queryObj), 'AIA-TDA-2022').toString();
            const query = encodeURIComponent(encryptedQuery);
            this.url = `${window.location.origin}/#/report?val=${query}`;
        }
    },   
    watch: {
        "$i18n.locale"() {
            this.generateQrLink();
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.getQRSize);
    },
    mounted() {
        this.getQRSize();
        window.addEventListener('resize', this.getQRSize);
        this.generateQrLink();
    },
}
</script>


<style scoped>

.continue-button {
    background: #333D47;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
    border-radius: 240px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    color: white;
    text-align: center;
    letter-spacing: 0.231689px;
    height: 240px;
    width: 480px;
    position: relative;
    top: -120px;
}
.description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    letter-spacing: 0.25px;
    color: #333D47;
    padding: 80px 123px 120px 123px;
    background-color:  rgba(255, 255, 255, 0.9);
    border-radius: 0px 0px 36px 36px;
    position: relative;
}
.general-wellness {
    font-size: 40px;
    padding-top: 80px;
}
.general-wellness-shadow {
    position: relative;
    transform: translate(-50px, -10px);
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    line-height: 350px;
    font-size: 400px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    width: 100%;
    text-align: center;
}
.general-wellness-value {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 800;
    font-size: 400px;
    line-height: 350px;
    letter-spacing: 0.25px;
    color: #C6DDEA;
    position: absolute;
    width: 100%;
    transform: translate(10px, 10px);
    top: 0;
}
.header {
    background-color:rgba(165, 145, 92, 0.9);
    border-radius: 24px 24px 0px 0px;
    padding: 120px 80px;
}
.number {
    display: inline-block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 90px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #333D47;
    width: 90px;
    height: 90px;   
    border: 1px solid #333D47;
    border-radius: 56px;
    margin: 0 40px 15px 40px;
}
.opacity {
    opacity: 0.9;
}
.progress {
    background-color: #333D47;
    border-radius: 66px;
    width: 404px;
}
.progress-bar {
    background: #C6DDEA;
    border-radius: 66px;
}
.qr-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #5699C1;
    padding-top: 18px;
}
.question {
    padding-top: 60px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    gap: 90px;
}
.result-logo {
    height: 80px;
    width: 80px;
}
.start-content-container {
    position: absolute;
    top: 20%;
    left: 10%;
    width: 80%;
}
.stress-value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 84px;
    line-height: 90px;
    letter-spacing: 0.25px;
    color: #C6DDEA;
    text-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 8px 28px rgba(0, 0, 0, 0.08);
}

.subtitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    padding-top: 80px;
}
.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 68px;
    text-align: center;
    letter-spacing: 0.25px;
    color: white;
}
.vital-sign {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 59px;
    letter-spacing: 0.25px;
    color: #C6DDEA;
}
.vital-sign-value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 108px;
    line-height: 100px;
    letter-spacing: 0.25px;
    color: #C6DDEA;
    text-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 8px 28px rgba(0, 0, 0, 0.08);
}
@media (max-width: 2000px) {
    .continue-button {
        font-size: 24px;
        height: 90px;
        width: 200px;
        top: -45px;
    }
    .description {
        font-size: 24px;
        padding: 40px;
    }
    .general-wellness {
        padding-top: 0px;
        padding-bottom: 10px;
    }
    .general-wellness-shadow  {
        font-size: 250px;
        line-height: 220px;
        transform: translate(-40px, -10px);
    }
    .general-wellness-value {
        font-size: 250px;
        line-height: 220px;
        transform: translate(5px, 5px);
    }
    .header {
        padding: 30px 40px 50px 40px;
    }
    .number {
        font-size: 24px;
        line-height: 45px;
        height: 45px;
        width: 45px;
    }
    .progress {
        min-width: 129px;
        width: 250px;
        height: 0.7rem;
    }
    .qr-text {
        font-size: 16px;
        width: 150px;
    }
    .question {
        padding-top: 30px;
        gap: 10px;
    }
    .result-logo {
        width: 64px;
        height: 64px;
    }
    .steps {
        font-size: 16px;
    }
    .start-content-container {
        width: 90%;
        left: 5%;
        top: 150px;
        overflow: scroll;
        max-height: 88%;
    }
    .stress-value {
        font-size: 36px;
        line-height: 40px;
    }
    .subtitle {
        font-size: 16px;
        line-height: 20px;
        padding-top: 50px;
        margin: 0;
    }
    .title {
        font-size: 32px;
    }
    .vital-sign {
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 0;
    }
    .vital-sign-value {
        font-size: 40px;
        line-height: 43px;
    }
}

@media (max-width: 940px) {
    .start-content-container {
        top: 120px;
    }
}


</style>