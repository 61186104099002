<template>
  <div class="locale-changer normal d-flex align-items-center">
    <img src="./assets/locale-icon.svg" alt="" />
    <button
      v-for="locale in $i18n.availableLocales"
      :key="locale"
      :class="`option ${$i18n.locale == locale ? 'selected' : ''}`"
      @click="$i18n.locale = locale"
    >
      {{ locale }}
    </button>
    <!-- <select v-model="$i18n.locale" id="locale-selector" width="50px">
      <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
    </select> -->
  </div>
</template>

<script>
//import { useI18n } from 'vue-i18n';

export default {
  name: "locale-button",
  setup() {
    //const { t, locale } = useI18n();
    //console.log(t, locale);
  },
  methods: {},
};
</script>

<style scoped>
.locale-changer {
  gap: 8px;
}
.option {
  border: 1px solid #333d47;
  filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.2));
  border-radius: 240px;
  width: 80px;
  height: 100%;
  font-size: 18px;
  color: black;
  background-color: transparent;
}
.selected {
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
  background-color: #333D47;
  color: white;
}
</style>
