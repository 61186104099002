<template>
    <div class="result-card ">
        <img :src="require(`../assets/${content}-banner.png`)" alt="" class="w-100">            
        <div class="result-text">
            <img :src="require('../assets/'+ content + '.jpg')" alt="" class="lify-image float-end">
            <div class="title">
                <span>{{t('health_report.recommend.' + content + '.title')}}</span>
                <span class="subtitle" v-html="t('health_report.recommend.' + content + '.subtitle')"></span>
            </div>
            <div class="note">
                {{te('health_report.recommend.' + content + '.note') ? `(${t('health_report.recommend.' + content + '.note')})` : ''}}<br v-if="te('health_report.recommend.' + content + '.note')">
                {{t('health_report.recommend.' + content + '.ingredients')}}
            </div>
            <div class="description" v-html="t('health_report.recommend.' + content + '.description')">
            </div>
        </div>
    </div> 
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
    setup() {
        const {t, te, locale} = useI18n();

        return {
            t, locale,te
        }
    },
    props: {
        content: String,
        type: String,
        index: Number,
    },
}
</script>

<style scoped>
.result-card {
    height: 100%;
}
.result-text {
    padding: 56px;
    padding-top: 0;
}
.lify-image {
    width: 174px;
    height: 174px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 15px;
    margin-bottom: 10px;
}
.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 132%;
    letter-spacing: 0.1971px;
    padding-bottom: 24px;
}
.note {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 132%;
    text-align: justify;
    letter-spacing: 0.1971px;
    padding-bottom: 24px;
}
.description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: justify;
    letter-spacing: 0.1971px;
    color: #333D47;
}
a {
    background: #FE805C;
    border-radius: 18.9216px;
    height: 31.5360050201416px;
    width: 111.95281219482422px;
}
button {
    text-decoration: none;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.19710002839565277px;
    text-align: center;
    color: white;
    width: 100%;
    height: 100%;
}
.subtitle {
    font-size: 24px;
}
@media (max-width: 2000px) {
    .title {
        font-size: 30px;
        padding-bottom: 10px;
    }
    .description {
        font-size: 16px;
    }
    .lify-image {
        width: 100px;
        height: 100px;
    }
    .fittery-image {
        width: 80px;
        height: 80px;
    }
}
</style>