<template>
    <div class="start-content" id="start-content">
      <div class="header w-100 m-0 ">
        <Vue3Lottie :animationData="faceAnimation" :height="160" :width="160" :loop="true" />
        <p class="message" v-html="t('message.final_check', {second: countdown})"></p>
      </div>
      <div class="description">
        <conditions-hud v-if="conditions" :conditions="conditions"/>
      </div>
  </div>
</template>

<script>
import faceAnimation from "./assets/face-animation.json"
import ConditionsHud from "./conditions-hud.vue"
import {useI18n} from "vue-i18n";
export default {
  setup() {
    const {t} = useI18n();
    return {
      t
    }
  },
  props: {
    countdown: Number,
    conditions: Object,
  },
  components: {
    ConditionsHud,
  },
  data() {
    return {
        faceAnimation
    }
  }
}
</script>

<style scoped>
.description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    letter-spacing: 0.25px;
    color: #333D47;
    text-align: justify;
    padding: 80px 123px 115px 123px;
    background-color:  rgba(255, 255, 255, 0.9);
    width: 100%;
    border-radius: 0px 0px 36px 36px;
}

.header {
    background-color:rgba(165, 145, 92, 0.9);
    border-radius: 24px 24px 0px 0px;
    padding: 120px 80px;
}

.message {
  margin-top: 30px;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: white;
  font-size: 24px;
}
.start-content {
    position: absolute;
    top: 20%;
    left: 10%;
    width: 80%;
    max-height: 76vh;
    border-radius: 24px;
    overflow: scroll;
}
.subtitle {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    color: white;
}
.title {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 56px;
    text-align: center;
    color: white;
}
.vital-sign {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.25px;
    color: white;
    padding-top: 44px;
}

.description .title {
    font-weight: 600;
    font-size: 24px;
    color: black;
    margin-bottom: 24px;
}

.description .content {
    font-weight: 300;
    font-size: 14px;
    line-height: 160%;
    color: #333D47;
    text-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 8px 28px rgba(0, 0, 0, 0.08);
    margin-bottom: 24px;
    padding-left: 20px;
}

@media (max-width: 2000px) {
  .description {
      font-size: 16px;
      padding: 40px;
  }
  .header {
      padding: 80px 40px 48px 40px;
  }
  .question {
      padding-top: 30px;
  }
  img {
      width: 64px;
  }
  .number {
      font-size: 24px;
      line-height: 45px;
      height: 45px;
      width: 45px;
  }
  .start-content {
      width: 90%;
      left: 5%;
      top: 150px;
  }
  .subtitle {
      font-size: 16px;
  }
  .title {
      font-size: 32px;
  }
  .vital-sign {
      font-size: 24px;
      padding-top: 24px;
  }
}

@media (max-width: 940px) {
    .start-content {
        top: 30%;
    }
}

@media screen and (width: 2160px) and (height: 3180){
    .start-content {
        top: 25%;
        left: 12.5%;
        width: 75%;
    }
}
</style>