export default {

  log: function(message, category) {

    return fetch('/api/log', {
      method: 'PUT',
      headers: { 'Content-type': 'application/json; charset=UTF-8'},
      body: JSON.stringify({
        category: category,
        message: message
      })
    });
  },

  info: function(message) {
    this.log(message, 'info');
  },

  debug: function(message) {
    this.log(message, 'debug');
  },

  error: function(message) {
    this.log(message, 'error');
  }

};
