// key: range1 | range2 | ...
// range: lower bound, upper bound
const vitalNormalRanges = {
  bloodPressureSystolic: "90,120|70,140",
  bloodPressureDiastolic: "60,80|40,90",
  bmi: "18,24|13,29",
  respiratoryRate: "9,20|6,24",
  generalRisk: "0,10|0,20",
  covidRisk: "0,10|0,20",
  generalWellness: "70,100|40,100",
  heartRate: "50,75|42,83",
  hrvSdnn: "35.5,200|16.4,200",
//  hrv_sdnn: "92,162|68,219",
  hrvIbi: "800,1200|722,1428",
  spo2: "95,100",
  stress: "0,2|0,3",
  temperature: "36.3,37.5|35,37.5",
  facialSkinAge: "0,100"
};
const categoryToString = ["excellent", "good", "poor"];
const colors = {
  excellent_: "#9FD9CC",
  good_upper: "#E8EB9E",
  good_lower: "#E8EB9E",
  poor_upper: "#D83131", 
  poor_lower: "#D83131",
  anomaly: "#000000"
};

function checkCondition(tag, value) {
  let bound = '';
  let category = 2;
  if (value === undefined) {
    return categoryToString[category];
  }
  if (tag === "bloodPressure") {
    let systolic;
    let diastolic;
    let systolicRanges = vitalNormalRanges["bloodPressureSystolic"].split(
      "|"
    );
    let diastolicRanges = vitalNormalRanges["bloodPressureDiastolic"].split(
      "|"
    );
    systolic = value.split("/")[0];
    diastolic = value.split("/")[1];
    let index = 0;
    for (let i = 0; i < systolicRanges.length; i++) {
      let systolicRange = systolicRanges[i].split(",");
      let diastolicRange = diastolicRanges[i].split(",");
      let lbSystolic = parseFloat(systolicRange[0]);
      let ubSystolic = parseFloat(systolicRange[1]);
      let lbDiastolic = parseFloat(diastolicRange[0]);
      let ubDiastolic = parseFloat(diastolicRange[1]);
      let condition1 = lbSystolic <= systolic && lbDiastolic <= diastolic;
      let condition2 = systolic <= ubSystolic && diastolic <= ubDiastolic;
      if (condition1 && condition2) {
        category = index;
        break;
      }
      if (lbSystolic >= systolic || lbDiastolic >= diastolic) {
        bound = 'lower';
      } else if (systolic >= ubSystolic || diastolic >= ubDiastolic) {
        bound = 'upper';
      }
      index += 1;
    }
  }
  if (typeof value === "string") {
    return `${categoryToString[category]}_${bound}`;
  }

  if (Object.keys(vitalNormalRanges).includes(tag)) {
    let boundaries = vitalNormalRanges[tag].split("|");
    let index = 0;
    for (var boundary of boundaries) {
      boundary = boundary.split(",");
      let lb = parseFloat(boundary[0]);
      let ub = parseFloat(boundary[1]);
      if (lb <= value && value <= ub) {
        category = index;
        break;
      }
      if (lb >= value) {
        bound = 'lower'
      } else if (value >= ub) {
        bound = 'upper'
      }
      index += 1;
    }
  }
  return `${categoryToString[category]}_${bound}`;
}

function getRange(tag, min, max, dataRange) {
  if (Object.keys(vitalNormalRanges).includes(tag)) {
    let boundaries = vitalNormalRanges[tag].split("|");
    let ranges = [0, dataRange];
    for (var boundary of boundaries) {
      boundary = boundary.split(",");
      ranges.push(Number(boundary[0]));
      ranges.push(Number(boundary[1]));
    }
    ranges.sort((a,b) => a-b);

    let gradient = []

    for (let i = 0 ; i < ranges.length-1 ; i++) {
      let offset = (ranges[i+1] + ranges[i]) / 2 / dataRange;
      let color = checkCondition(tag, offset*dataRange);
      gradient.push({offset: (offset-min)/(max-min), color: colors[color]})
    }
    return gradient;
  }
}

export { checkCondition, colors, getRange };

