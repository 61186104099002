import { companyUser } from './user-definitions'
import { cloudBackend, UserManagerInterface } from './company-user-manager-definitions'
import { FirebaseUser } from './company-user-firebase'
import { LocalUser } from './user-local'
import { AwsUser } from './company-user-aws'

class UserManager implements UserManagerInterface {

    currentUser: companyUser | undefined
    constructor() { }
    async login(backend: cloudBackend): Promise<void> {
        if (this.currentUser && this.currentUser?.userType != backend) {
            delete this.currentUser
            await this.ready(backend)  // re-create new user with the correct backend.
        }

        if (this.currentUser) {
            switch (backend) {
                case (cloudBackend.firebase): {
                    await this.currentUser.login("#firebaseui-auth-container")
                    break
                } 
                case (cloudBackend.aws): {
                    await this.currentUser.login()
                    break
                }
                case (cloudBackend.local): {
                    await this.currentUser.login()
                    break
                }
            }
            await this.currentUser.getCompanySettings()
            await this.currentUser.loadData()
        }
    }
    async ready(backend: cloudBackend): Promise<void> {
        switch (backend) {
            case cloudBackend.firebase: {
                this.currentUser = new FirebaseUser();
                break;
            } 
            case cloudBackend.aws: {
                this.currentUser = new AwsUser();
                break;
            }
            case cloudBackend.local: {
                this.currentUser = new LocalUser();
                break;
            }
        }
        if (this.currentUser) { await this.currentUser.ready() }
    }
    async logout(): Promise<void> {
        await this.currentUser?.logout()
        this.currentUser = undefined
        window.sessionStorage.clear();
        // console.log("%cLogout success", "color: blue");
    }

}

const newUserManager = new UserManager();

export { newUserManager }