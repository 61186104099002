const locales = {
  '繁體': {
    page: {
      dashboard: '面板',
      settings: '設置',
      help: '幫助',
      logout: '登出',
      supervisor_view: '管理員視圖',
      coming_soon: '敬請關注!<br>我們即將到來...',
    },
    guest_signin: '繼續以訪客身份登錄',
    logout_prompt: '你確定你要退出嗎？',
    instruction: {
      stay_still: '正在測量中，請保持靜止。',
      count_down1: '將在',
      count_down2: '秒後開始測量，請保持靜止。',
    },
    button: {
      mode: '顯示模式',
      fever_percentage: '臉上發燒皮膚比例',
      fever_threshold: '發燒檢查體溫',
      start: '開始',
      stop: '停止',
      shutdown: '關機',
      cancel: '取消',
      ok: '確定',
      all: '所有',
      power_on: '開機',
      full_screen: '全屏',
      save_settings: '保存設置',
      upgrade: '升級',
      change_password: '更改密碼',
      next: "下一步",
      previous: "上一步",
      finish: "完成",
      continue: '繼續',
      show_health_report: '顯示健康報告',
      clear_user_information: '清除用戶資料',
      send_to_email: '發送到郵件',
      rate_us: ' 評價我們！',
      submit: '提交',
      on: "ON",
      off: "OFF",
      start_again: "重新開始",
      back_to_homepage: "返回主頁",
      agree: "已閱讀及同意",
      disagree: "不同意",
      immediately: '立即開始'

    },
    labels: {
      sending: '正在發送...',
      user_profile: '用戶資料',
      rate_us: ' 您的反饋意見對我們很重要，請給我們評個分！',
      poor: '劣',
      good: '優',
      dissatisfied: '不滿意',
      satisfied: '滿意',
    },
    graph: {
      measurement: '測量',
      time: '時間',
      day: '日',
      week: '周',
      month: '月',
      trend_line: '顯示趨勢線',
      excellent: "優秀",
      good: "良好",
      poor: "欠佳",
    },
    mode: {
      normal: '正常',
      debug: '調校'
    },
    threshold: {
      auto: '自動'
    },
    message: {
      startup: '系統已關閉。請按「開始」。',
      loading: '加載中',
      calibrating: '保持靜止，智能系統調整中 ...',
      no_face_box: '找不到人臉 ...',
      stopping: '{system_short_name} 智能系統停止中...',
      confirm_shutdown: '確定要關閉主機嗎?',
      important_message: '重要訊息',
      emmisivity: '{short_name} v{version} 是專為 Emmisivity <span class="text-danger font-weight-bold">{emmisivity}</span> 的 FLIR A315 而設計的。',
      auto_sys_restart: '自動重新啟動',
      prevent_overheat: '避免系統過熱，重新啟動中...',
      camera_error: '發現相機錯誤',
      system_error: '發現系統錯誤',
      camera_fatal_error: '因相機問題，系統自動停止，請聯絡 {contact}。謝謝。',
      system_fatal_error: '因系統發生問題，系統自動停止，請聯絡 {contact}。謝謝。',
      system_off: '系統已關閉。',
      tap_to_view: '按一下觀看。',
      condition_check_failed: '(1) 請移動進人形框內 <br/>(2) 盡量保持靜止。',
      coming_soon: '即將推出',
      breath_in: '吸',
      breath_out: '呼',
      bad_scan_message: '請重新掃描',
      weak_signal_message: '信號微弱',
      settings_updated: '設定已更新',
      password_updated: '密碼已更新',
      password_update_failed: '更改密碼失敗，請檢查您的密碼是否正確',
      fill_profile: '請填寫您的用戶資料',
      conditions: {
        lighting: "燈光",
        distance: "距離",
        centered: "置中",
        movement: "靜止",
        server_ready: "系統準備就緒"
      },
      subscribe: "訂閱以觀看內容!",
      scan_quality: "掃描質量",
      email: '電郵發送至',
      feedback: "已收到您的意見，非常感謝！祝您有個美好的一天~",
      final_check: "請正面望向鏡頭，人臉影像掃描將於 {second} 秒後開始",
      scanning_progress: {
        title: "掃描進行時：",
        content: [
          "請企穩",
          "回答下列問題",
          "保持正常呼吸",
          "保持人臉在鏡頭中"
        ]
      },
      camera_preparation: "我們正在加載中，請確保您的臉朝向鏡頭！",
      loading_wait: "正在加載中，請耐心等候。",
      wait_result: "分析結果中…請耐心等候…",
    },
    disclaimer: {
      disclaimer1: "免責聲明：此軟件不儲存任何個人資料。",
    },
    copyright: "\u00A9 2021-2023 全境智能有限公司 版權所有",
    last_scan: '最後一次掃描',
    last_update: '最後更新',
    vs_descriptions: {
      bloodPressure: "成人的血壓小於120/80 mmHg。",
      bmi: "成人的身體質量指數在18.5與24.9之間。",
      respiratoryRate: "成人的呼吸率一般在每分鐘6至24次。",
      generalRisk: "得到心血管疾病的概率，越小越好。",
      covidRisk: "得到新冠肺炎的概率，越小越好。",
      heartRate: "成人的靜息心率在每分鐘60至100下。",
      hrvSdnn: "成人的心率變化大約是20ms或以上。",
      //      hrvSdnn: "成人一天内的心率變化大約是68-219ms之間。",
      hrvIbi: "成人的靜息IBI在600至1000 ms之間。",
      spo2: "成人的血氧量大約爲95-100%。",
      stress: "正常的壓力指數應低於2。",
      temperature: "成人的正常體溫大約是37攝氏度。",
      facialSkinAge: " 您一直都年輕美麗/帥氣!",
    },
    option: {
      yes: '有',
      no: '沒有',
      logout_yes: '是',
      logout_no: '不是',
    },
    user: {
      supervisor: '管理員',
      guest: '訪客',
    },
    company_info: {
      company_information: "公司用戶信息",
      company_id: "公司用戶編號",
      project_id: "項目編號",
      site_id: "站點編號",
      location: "站點位置",
      subscription_plan: '訂閲計劃',
      valid_until: '有效期至',
    },
    user_info: {
      user_id: '用戶編號',
      profile_image: '頭像',
      personal_information: '個人信息',
      subscription_plan: '訂閲計劃',
      valid_until: '有效期至',
      name: '名稱',
      nickname: '綽號',
      birthday: '生日',
      height: '身高（厘米）',
      weight: '體重（公斤）',
      gender: '出生性別',
      male: '男',
      female: '女',
      unit_system: '單位系統',
      country: '國家',
      countryOptions: {
        hongkong: "香港，中國",
        china: "中國大陸",
      },
      metric: '公制',
      imperial: '英制',
      medical_history: '病史',
      smoking: '您有吸煙嗎？',
      hypertension: '您有高血壓嗎？',
      bp_medication: '您有在服用降血壓藥物嗎？',
      diabetic: '您是糖尿病患者嗎？',
      heart_disease: '您有心臟疾病嗎？',
      depression: '您有抑鬱症嗎?',
      department: '部門',
      type_1: '類型1',
      type_2: '類型2',
      plan_type: {
        free: '免費',
        pro: "專業",
        enterprise: '企業',
        team: '團隊',
        unlimited: '無限制'
      }
    },
    password: {
      password: '密碼',
      old: '舊密碼',
      new: '新密碼',
      confirm: '確認密碼',
      not_match: '密碼不相符'
    },
    detailed_info: {
      history: '歷史數據',
      trend_analysis: '趨勢分析',
      note: '注釋',
      references: '參考資料',
      content: {
        anomalies: "在 {date}，您的 {vital_sign} 記錄有異常情況",
      },
    },
    no_data: '沒有數據',
    help_content: {
      disclaimer: {
        title: "免責聲明",
        p1: "本系統是以攝像頭為基礎的非接觸式健康監測方案，可測量生命體徵如心率、呼吸頻率、血壓和血氧飽和度等。 捕捉影像只會作計算之用，並不會存儲任何影片片段。",
        p2: "WVS適合不需要持續或密集健康監測的個人用戶，且不能用作測量生命體徵的唯一方式或取代與醫學專家的諮詢。系統的生命體徵數據不適用於醫療目的， 如果您有任何進一步的查詢，請聯繫專業的醫療人員。"
      },
      setup: {
        title: "設置指南",
        dos: {
          title: "掃描進行時，請...",
          p1: "爲了更好的結果和使用體驗，請按以下指示完成掃描：",
          1: "將攝像頭放置於視線水平附近",
          2: "不要搖晃設備",
          3: "在有充足光源的地方進行掃描，確保光線（最好室内光源）平均照到臉上",
          4: "請避免背光",
          5: "請保持臉部在綠框中間及面向鏡頭",
          6: "請確保和鏡頭之間有合適的距離"
        },
        donts: {
          title: "掃描進行時，請不要...",
          1: "不要帶口罩",
          2: "不要帶帽子",
          3: "不要被任何東西遮擋臉部",
          4: "臉上不要有不均勻的光線 （例如陽光）",
          5: "不要談話",
          6: "身體不要大幅度移動",
          7: "不要把頭轉向旁邊"
        },
        low_accuracy: {
          title: "低準確率例子",
          1: "環境太暗或太光",
          2: "光線不均或背光",
          3: "佩戴口罩或帽子或被東西遮擋臉部",
          4: "沒有面向鏡頭",
          5: "與鏡頭的距離太遠",
          6: "與鏡頭的距離太近",
        },
      },
      operation: {
        title: "操作建議",
        ground_truth: {
          title: "請先使用接觸式設備測量數據",
          p1: "在激活 WVS 系統之前，用戶應先用接觸式脈搏血氧儀作量度，再將 WVS 系統的結果與接觸式脈搏血氧儀的結果進行比較，以得知結果的可參考性。",
          p2: "請按照以下步驟以打開和操作接觸式脈搏血氧儀：",
          1: "擠壓設備背面將其打開。",
          2: "將食指或無名指插入設備，顯示屏朝上。 慣用右手的使用者，請使用右手食指或無名指。 慣用左手的使用者，請使用左手食指或無名指。",
          3: "指尖應觸碰到設備內部的手指擋塊。 如果手指沒有正確定位，請從步驟 1 再次開始。",
          4: "該設備的顯示屏上將顯示您的生命體徵",
          p3: `有關如何使用接觸式設備的更詳細說明，請參閱<a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">此處</a>的設備手冊。`
        },
        scanning: {
          title: "健康掃描步驟",
          p1: "使用 WVS 掃描系統的步驟如下：",
          1: "掃描開始前，先選擇光源充足的地方舒適地就座，確保光線（最好是室内光源）平均地照到臉上。然後將電腦放置在穩定的平面上，避免設備搖晃。",
          2: "點擊 WVS 系統左側導覽列上的主頁按鈕（第一個圖標），再點擊右上角的綠色「開機」按鈕以啟動相機和面部掃描儀系統。 該頁面應顯示「正在啟動... 」，如下圖所示。如提示允許 WVS 使用您的相機，請點擊允許。",
          caption_2: "生命體徵掃描儀啟動中",
          3: "系統準備就緒後將啟動攝像頭並顯示鏡頭畫面，同時顯示一個綠色框代表檢測到的面部範圍。請確保身體和鏡頭之間有合適的距離，保持臉部在綠框中間及面向鏡頭。",
          caption_3: "系統已啟動",
          4: "點擊綠色圓圈「開始」按鈕以開始掃描程序。 在開始掃描之前，您需要遵循掃描儀中顯示的條件。 滿足條件後，屏幕會出現三秒倒計時，表示掃描即將開始。",
          caption_4: "掃描啟動條件",
          5: "掃描進行時請跟隨綠色框下方綠色圓圈中出現的呼吸指示調整呼吸。 呼吸指示將在「吸氣」和「呼氣」之間切換，如下圖所示，您可從掃描進度圓圈中看到剩餘所需的時間。",
          note_5: "注意：如呼吸速度與指示不吻合，掃描不會檢測到錯誤，但仍建議跟隨呼吸指示以正常靜息速度呼吸。",
          caption_5: "掃描開始",
          6: "掃描會在30秒內完成，並在頁面上顯示生命體徵結果。請等待掃描完成以查看結果。",
          caption_6: "基本生命體徵",
          7: "若您想瀏覽詳細的掃描報告，請點擊導覽列上的第二個圖示前往數據板。",
          caption_7: "掃描結果概覽",
          laptop: "使用手提電腦",
          phone: "使用電話",
          pc: "使用桌上型電腦"
        }
      }
    },
    scan_tutorial: {
      title: "Vitals™ 掃描快速指南",
      dos: "請......",
      donts: "請不要......",
      step1: {
        title: "保持身體處於綠色邊框內",
        paragraph: "點擊開始按鈕後，屏幕上將出現綠色人形邊框。 邊框將指引您到正確的位置和距離使掃描更有效。 請確保您處於綠色邊框內。",
        do1: "將相機放置在視線水平附近",
        do2: "將臉部保持在邊框內並面向相機",
        do3: "確保您和相機之間距離適當",
        dont1: "不要坐在離設備太遠或太近的位置",
      },
      step2: {
        title: "確保相機平穩",
        paragraph: "相機搖晃可能會影響掃描的閱讀。請確保設備被放在平穩的位置並儘量減少移動。",
        do1: "將設備放在平穩的表面上",
        do2: "避免晃動設備",
        dont1: "不要談話",
        dont2: "身體不要大幅度移動 "
      },
      step3: {
        title: "在相機上顯示整張臉",
        paragraph: "確保您的臉完整顯示在相機上。 拿走任何會遮擋臉部的東西。",
        do1: "面向相機",
        do2: "確保您的臉沒有被帽子、連帽衫、口罩、頭髮等遮住。",
        dont1: "不要戴任何會遮住臉的東西（帽子、連帽衫、口罩）",
        dont2: "不要側過臉"
      },
      step4: {
        title: "E確保臉上的光線均勻充足",
        paragraph: "不均勻的光線可能會影響閱讀的準確性。 建議使用室內照明以更容易確保照明正確。",
        do1: "選擇光線充足的區域，確保光線（最好是室內光線）均勻地照射在臉上",
        dont1: "臉上不要有不均勻的光線（例如：陽光）",
        dont2: "背光"
      }
    },
    health_report: {
      title: "個人健康報告",
      excellent: "出色",
      poor: "不佳",
      age: "年齡",
      sex: "性別",
      email: "電郵",
      phone_number: "電話號碼",
      female: "女",
      male: "男",
      height: "身高",
      weight: "體重",
      bmi: "BMI",
      measured: "如何量度？",
      general_wellness: "綜合健康分數",
      stress_level: "壓力水平",
      facial_skin: {
        young: "年輕",
        healthy: "健康",
        average: "正常",
        mature: "成熟",
        excellent: "極佳",
        fair: "一般",
        need_improvement: "有待改善"
      },
      stress: {
        high: "高",
        medium: "中等",
        low: "低",
      },
      wellness: {
        title: "整體健康",
        generalWellness: {
          title: "綜合健康",
          definition: "綜合健康反映您的整體健康狀況。",
          range: "正常的綜合健康分數為80-100",
          result: "你的綜合健康分數是{value}",
          measured: "綜合健康分數是通過核心生命體徵和精神壓力水平的加權分數來計算的。"
        },
        stress: {
          title: "壓力水平",
          definition: "壓力是一種情緒或身體緊張的感覺。 它可能來自任何讓你感到沮喪、憤怒或緊張的事件或想法。",
          range: "正常的靜息壓力等級為 0-2。",
          result: "你的壓力水平等級為{value}.",
          measured: "壓力是根據您的心率變異度 (HRV) 來衡量的，心率變異度是您心臟每次波動的頻率。 較高的心率變異度通常表示壓力較小，因為您的心臟能夠更好地應對各種壓力來源。"
        }
      },
      facial: {
        title: "面部",
        facialSkinAge: {
          title: "面部皮膚年齡",
          definition: "面部皮膚年齡是指根據您的皮膚狀況來估計的皮膚年齡。 結果可能受到不同情況的影響，例如化妝品/皮膚護理的使用、睡眠不足等。",
          range: "越年輕越好！",
          result: "您一直都年輕迷人！",
          measured: "面部皮膚年齡是通過使用機器學習演算法以分析您的面部狀況。"
        }
      },
      respiratory: {
        title: "呼吸系統",
        respiratory_rate: {
          title: "呼吸率",
          definition: "呼吸頻率為一個人每分鐘呼吸的次數。 呼吸頻率可能會隨著發燒、疾病、壓力和其他醫療狀況而上升。",
          range: "成年人靜息時正常呼吸頻率為每分鐘 12 至 16 次",
          result: "您的呼吸頻率為每分鐘 {value}次。"
        },
        spo2: {
          title: "血氧飽和度",
          definition: "血氧飽和度量度血紅細胞從肺部攜帶到身體其他部位的氧氣量。 低血氧症的常見症狀包括頭痛、心率加快、氣短等。",
          range: "大多數人的血氧飽和度為95% 或以上。",
          result: "你的血氧飽和度為 {value}%."
        }
      },
      cardiovascular: {
        title: "心血管系統",
        heartRate: {
          title: "心率",
          definition: "心率是每分鐘心臟跳動的次數 (bpm)。 高心率可能是由荷爾蒙問題、焦慮、大量攝入咖啡因/酒精、貧血等引起的。",
          range: "正常的靜息心率範圍為每分鐘60-100次。",
          result: "你的心率為每分鐘{value}次",
        },
        hrv_sdnn: {
          title: "心率變異度SDNN值",
          definition: "心率變異度是每次心跳之間時間變化的量度。 SDNN 是正常竇性搏動的 IBI 的標準偏差，以毫秒(ms) 為單位。低心率變異度可能是由睡眠不足的壓力造成的。",
          range: "100毫秒以上的心率變異度SDNN 是健康的。",
          result: "您的心率變異度 SDNN 為 {value} 毫秒。"
        },
        hrv_ibi: {
          title: "心率變異度IBI值",
          definition: "心率變異度是每次心跳之間時間變化的量度。 IBI 是心臟的搏動間期。 在心臟功能正常的情況下，每個 IBI 值會隨心跳改變。",
          range: "典型的心率變異度 IBI 值範圍為 500-1300 毫秒。",
          result: "您的心率變異性 IBI 為 {value} 毫秒。",
        },
        systolic_bp: {
          title: "收縮壓",
          definition: "收縮壓是由心肌收縮引起的肱動脈的峰值壓力。 低血壓可能是由脫水、懷孕、休克、內分泌失調等引起的。",
          range: "理想的收縮壓在 90-120mmHg 之間。",
          result: "您的收縮壓為 {value} mmHg。",
        },
        diastolic_bp: {
          title: "舒張壓",
          definition: "舒張壓是當您的心肌放鬆時您的肱動脈的壓力量。 高血壓可能是由不健康的生活方式或某些健康狀況引起的，例如糖尿病和肥胖。",
          range: "理想的舒張壓在 60-80 mmHg 之間。",
          result: "您的舒張壓為 {value} mmHg。"
        },
      },
      personal_health_goal: {
        title: "Personal Health Goal",
        gain_muscle: {
          title: "Gain Muscle",
          definition: "If your goal is to gain msucle, you should focus on a high protein and moderate carbohydrate diet to compliment your exercise regime. Each meal should contain at least 40g of lean protein to ensure your body is optimized for muscle recovery post workout."
        },
        lose_weight: {
          title: "Lose Weight",
          definition: "If your goal is to lose weight, you will need to consume fewer calories than you burn each day. When this happens, your body uses stored energy (i.e. fat) to make up for the calorie deficit. We recommend a diet that has moderate lean protein and low carbohydrate to achieve this outcome."
        }
      },
      recommend: {
        young: {
          title: "Young+ ",
          subtitle: "骨膠原洋甘菊烏龍橙茶",
          ingredients: "成分：烏龍茶, 洋甘菊, 蕁麻, 橙皮",
          description: "美顏配方注入草飼料牛膠原蛋白粉，助保持皮膚及骨骼健康，促進消化",
          image: "./../assets/young.jpg"
        },
        sooth: {
          title: "Sooth ",
          subtitle: "羅漢果菊花夏枯草",
          ingredients: "成分：羅漢果, 夏枯草, 菊花",
          note: "不含咖啡因",
          description: "清熱潤肺，滋養及平衡內臟功能",
          image: "./../assets/sooth.jpg"
        },
        sleep: {
          title: "Sleep ",
          subtitle: "洋甘菊南非國寶茶",
          ingredients: "成分：南非國寶茶, 茴香, 洋甘菊",
          note: "不含咖啡因",
          description: "幫助睡眠，舒緩緊張，抗氧化",
          image: "./../assets/sleep.jpg"
        },
        energy: {
          title: "Energy ",
          subtitle: "薑黃力量 ",
          ingredients: "成分：紅茶, 薑黃, 肉桂, 黑胡椒,可可果皮",
          description: "提升體力及免疫力，抗炎，促進血液循環",
          image: "./../assets/energy.jpg"
        },
        sunset: {
          title: "Sunset ",
          subtitle: "洛神花橙茶",
          ingredients: "成分：洛神花, 玫瑰果, 甘草, 香茅",
          note: "不含咖啡因",
          description: "混合優質草本與洛神花，幫助身體放鬆，舒緩壓力, 高度抗氧化",
          image: "./../assets/sunset.jpg"
        },
        lify_banner: "./assets/lify-banner-tc.jpg",
        gain_muscle: {
          title: "Gain Muscle",
          description:
            "If your goal is to gain muscle, you should focus on a high protein and moderate carbohydrate diet to compliment your exercise regime. Each meal should contain at least 40g of lean protein to ensure your body is optimized for muscle recovery post workout.<br/><br/>Please refer to your HEALTH REPORT for the recommended weekly meal plan menu.",
          diet: [
            'Perfect for bulking mass with optimal macros',
            'Average Calories Per Meal > 550 kcal'
          ],
          points: [
            'High Protein',
            'Moderate Fats',
            'High Carbs',
          ],
          promotion: "At FITTERY, we recommend our Bulk Up meal plan program (<a href='https://fittery.com.hk/products/bulk-up'>https://fittery.com.hk/products/bulk-up</a>) which provides on average 50g of protein per meal. Remember to use special promo code HEALTHY25 to enjoy 25% off!",
          image: "../assets/gain-muscle.svg",
        },
        lose_weight: {
          title: "Lose Weight",
          description:
            "If your goal is to lose weight, you will need to consume fewer calories than you burn each day. When this happens, your body uses stored energy (i.e. fat) to make up for the calorie deficit. We recommend a diet that has moderate lean protein and low carbohydrate to achieve this outcome.<br/></br>Please refer to your HEALTH REPORT for the recommended weekly meal plan menu.",
          diet: [
            'Perfect for losing weight on an optimised diet',
            'Average Calories Per Meal < 400 kcal'
          ],
          points: [
            'Moderate Protein',
            'Low Fats',
            'Low Carbs'
          ],
          promotion: "At FITTERY, we recommend either our Low Carb or Light N Easy meal plan program (<a href='https://fittery.com.hk/pages/signature-plan-overview'>https://fittery.com.hk/pages/signature-plan-overview</a>) with each meal only approx. 300-400 calories. Remember to use special promo code HEALTHY25 to enjoy 25% off!",
          image: "../assets/lose-weight.svg",
        }
      },

      disclaimer: {
        title: "免責聲明",
        p1: "以攝像頭為基礎的健康監測方案設計用於非接觸式測量健康生命體徵，例如心率、呼吸頻率、血壓和 O2 飽和度。 視頻片段將通過設備的攝像頭捕捉，但不會存儲任何視頻片段。",
        p2: "該設備不能用作個人健康生命體徵測量的唯一方法，也不能取代與醫學專家的諮詢。 而且測量不用於醫療目的。 如果您有任何進一步的健康查詢，請立即聯繫醫療保健專業人員或其他醫學專家。",
        powered_by: '由',
        powered_by_2: '提供技術支持'
      },
      wait: "<br>謝謝!<br>請稍等，您的報告正在生成中⋯⋯<br>您亦同時可以在智能平板上找到您的專屬建議！",
      finish: [
        '<br>您的報告已準備完成！<br>您可以檢查您的下載檔案或',
        '按此',
        '如果您未能找到已下載檔案'
      ],
    },
    start_content: {
      title: "A.I.智能養生體驗",
      subtitle: "想了解哪款養生茶至適合你？立即體驗智能養生分析，等A.I.話您知！",
      stress: "膚齡",
      wellness: "壓力水平",
      report: "健康指數",
      tea: "專屬養生茶",
      terms_condition: {
        title: "條款及細則",
        content: [
          "此體驗是以攝像鏡頭為基礎的非接觸式健康監測方案，捕捉之影像及客戶所提供之數據只會作即時計算之用，並不會存儲任何人臉影像片段及相關數據。",
          "此體驗提供的資訊、功能、特色及服務僅作為資訊服務和一般性指引，並未必涵蓋與 閣下特定情況相關的所有事宜。在提供養生建議時，我們並非作為診所或專業執業醫生從事業務、進行管理或維持經營，因此我們對 閣下並無相應的護理責任。在任何情況下，此體驗均不擬作為個人或醫療診斷、建議或治療、醫療服務或用作醫療設備。",
          "此體驗所推薦之養生飲品並非用作治療、診斷或預防疾病用途。",
          "閣下作出任何與健康有關之決定，均應諮詢醫生或醫療護理專業人士。我們對該等資訊服務的輸出結果概不作出任何保證，依賴此體驗提供的任何資訊完全由 閣下自行承擔風險。",
          "服用任何處方藥時，請謹慎使用本產品（如果有的話）。我們堅持閣下在食用前諮詢醫療保健專業人士。特別是如果閣下有任何健康狀況、懷孕或哺乳或正在服用任何處方藥，請在食用前諮詢閣下的醫生。",
          "如閣下對任何食物過敏，可參考提示牌上每款養生茶的成份，如有需要，請向我們的服務專員尋求協助。"
        ],
        agree: "* 按「開始」即表示您已清楚閱讀並同意上述條款及細則",
      },
      steps: [
        "閱讀<br>條款及細則",
        "進行人臉影像<br>掃描",
        "查看適合你的<br>養生茶"
      ],
      warning: "如您想直接自行揀選喜愛的養生茶，歡迎使用點餐系統或向我們的服務專員尋求協助"
    },
    user_input: {
      title: "請輸入下列資料，以作更準確的健康分析：",
      question: "出生性別",
    },
    quiz: [
      {
        question: "平日您會經常感到......",
        options: [
          "平靜、放鬆",
          "壓力大、疲累",
          "脾氣暴躁",
          "全部皆非 ",
        ],
      },
      {
        question: "你的睡眠質素是... ",
        options: [
          "好而且不受干擾",
          "普遍還可以",
          "淺睡而且經常失眠",
        ],
      },
      {
        question: "你容易得傷風感冒嗎？",
        options: [
          "是 (一個月一次)",
          "有時候 (三個月一次)",
          "甚少 (一年一次)"],
      },
    ],
    scan_result: {
      title: "專屬您的健康分析",
      skin_age: "膚質",
      wellness: "健康指數",
      stress: "壓力水平",
      skip: "查看適合您的養生茶及飲食建議 >",
      email: "請輸入您的電郵，以獲取完整版的健康分析報告，<br>及LIFY Wellness的特選優惠：",
      email_placeholder: "輸入電郵",
      back: "返回健康分析",
      lify_recommendation: "適合您的養生茶",
      qr: "立即掃描二維碼<br>查看完整版的健康分析報告"
    },
  },
  '简体': {
    page: {
      dashboard: '面板',
      settings: '設置',
      help: '幫助',
      logout: '登出',
      supervisor_view: '管理員視圖',
      coming_soon: '敬請關注!<br>我們即將到來...',
    },
    guest_signin: '繼續以訪客身份登錄',
    logout_prompt: '你確定你要退出嗎？',
    instruction: {
      stay_still: '正在測量中，請保持靜止。',
      count_down1: '將在',
      count_down2: '秒後開始測量，請保持靜止。',
    },
    button: {
      mode: '顯示模式',
      fever_percentage: '臉上發燒皮膚比例',
      fever_threshold: '發燒檢查體溫',
      start: '開始',
      stop: '停止',
      shutdown: '關機',
      cancel: '取消',
      ok: '確定',
      all: '所有',
      power_on: '開機',
      full_screen: '全屏',
      save_settings: '保存設置',
      upgrade: '升級',
      change_password: '更改密碼',
      next: "下一步",
      previous: "上一步",
      finish: "完成",
      continue: '繼續',
      show_health_report: '顯示健康報告',
      clear_user_information: '清除用戶資料',
      send_to_email: '發送到郵件',
      rate_us: ' 評價我們！',
      submit: '提交',
      on: "ON",
      off: "OFF",
      start_again: "重新開始",
      back_to_homepage: "返回主頁",
      agree: "已閱讀及同意",
      disagree: "不同意",
      immediately: '立刻开始'
    },
    labels: {
      sending: '正在發送...',
      user_profile: '用戶資料',
      rate_us: ' 您的反饋意見對我們很重要，請給我們評個分！',
      poor: '劣',
      good: '優',
      dissatisfied: '不滿意',
      satisfied: '滿意',
    },
    graph: {
      measurement: '測量',
      time: '時間',
      day: '日',
      week: '周',
      month: '月',
      trend_line: '顯示趨勢線',
      excellent: "優秀",
      good: "良好",
      poor: "欠佳",
    },
    mode: {
      normal: '正常',
      debug: '調校'
    },
    threshold: {
      auto: '自動'
    },
    message: {
      startup: '系統已關閉。請按「開始」。',
      loading: '加載中',
      calibrating: '保持靜止，智能系統調整中 ...',
      no_face_box: '找不到人臉 ...',
      stopping: '{system_short_name} 智能系統停止中...',
      confirm_shutdown: '確定要關閉主機嗎?',
      important_message: '重要訊息',
      emmisivity: '{short_name} v{version} 是專為 Emmisivity <span class="text-danger font-weight-bold">{emmisivity}</span> 的 FLIR A315 而設計的。',
      auto_sys_restart: '自動重新啟動',
      prevent_overheat: '避免系統過熱，重新啟動中...',
      camera_error: '發現相機錯誤',
      system_error: '發現系統錯誤',
      camera_fatal_error: '因相機問題，系統自動停止，請聯絡 {contact}。謝謝。',
      system_fatal_error: '因系統發生問題，系統自動停止，請聯絡 {contact}。謝謝。',
      system_off: '系統已關閉。',
      tap_to_view: '按一下觀看。',
      condition_check_failed: '(1) 請移動進人形框內 <br/>(2) 盡量保持靜止。',
      coming_soon: '即將推出',
      breath_in: '吸',
      breath_out: '呼',
      bad_scan_message: '請重新掃描',
      weak_signal_message: '信號微弱',
      settings_updated: '設定已更新',
      password_updated: '密碼已更新',
      password_update_failed: '更改密碼失敗，請檢查您的密碼是否正確',
      fill_profile: '請填寫您的用戶資料',
      conditions: {
        lighting: "灯光",
        distance: "距离",
        centered: "置中",
        movement: "静止",
        server_ready: "系统准备就绪"
      },
      subscribe: "訂閱以觀看內容!",
      scan_quality: "掃描質量",
      email: '電郵發送至',
      feedback: "已收到您的意見，非常感謝！祝您有個美好的一天~",
      final_check: "请正面望向镜头，人脸影像扫描将于 {second} 秒后开始",
      scanning_progress: {
        title: "扫描进行时：",
        content: [
          "请站稳",
          "回答下列问题",
          "保持正常呼吸",
          "保持人脸在摄像头中"
        ]
      },
    },
    disclaimer: {
      disclaimer1: "免責聲明：此軟件不儲存任何個人資料。",
    },
    copyright: "\u00A9 2021-2023 全境智能有限公司 版權所有",
    last_scan: '最後一次掃描',
    last_update: '最後更新',
    vs_descriptions: {
      bloodPressure: "成人的血壓小於120/80 mmHg。",
      bmi: "成人的身體質量指數在18.5與24.9之間。",
      respiratoryRate: "成人的呼吸率一般在每分鐘6至24次。",
      generalRisk: "得到心血管疾病的概率，越小越好。",
      covidRisk: "得到新冠肺炎的概率，越小越好。",
      heartRate: "成人的靜息心率在每分鐘60至100下。",
      hrvSdnn: "成人的心率變化大約是20ms或以上。",
      //      hrvSdnn: "成人一天内的心率變化大約是68-219ms之間。",
      hrvIbi: "成人的靜息IBI在600至1000 ms之間。",
      spo2: "成人的血氧量大約爲95-100%。",
      stress: "正常的壓力指數應低於2。",
      temperature: "成人的正常體溫大約是37攝氏度。",
      facialSkinAge: " 您一直都年輕美麗/帥氣!",
    },
    option: {
      yes: '有',
      no: '沒有',
      logout_yes: '是',
      logout_no: '不是',
    },
    user: {
      supervisor: '管理員',
      guest: '訪客',
    },
    company_info: {
      company_information: "公司用戶信息",
      company_id: "公司用戶編號",
      project_id: "項目編號",
      site_id: "站點編號",
      location: "站點位置",
      subscription_plan: '訂閲計劃',
      valid_until: '有效期至',
    },
    user_info: {
      user_id: '用戶編號',
      profile_image: '頭像',
      personal_information: '個人信息',
      subscription_plan: '訂閲計劃',
      valid_until: '有效期至',
      name: '名稱',
      nickname: '綽號',
      birthday: '生日',
      height: '身高（厘米）',
      weight: '體重（公斤）',
      gender: '出生性別',
      male: '男',
      female: '女',
      unit_system: '單位系統',
      country: '國家',
      countryOptions: {
        hongkong: "香港，中國",
        china: "中國大陸",
      },
      metric: '公制',
      imperial: '英制',
      medical_history: '病史',
      smoking: '您有吸煙嗎？',
      hypertension: '您有高血壓嗎？',
      bp_medication: '您有在服用降血壓藥物嗎？',
      diabetic: '您是糖尿病患者嗎？',
      heart_disease: '您有心臟疾病嗎？',
      depression: '您有抑鬱症嗎?',
      department: '部門',
      type_1: '類型1',
      type_2: '類型2',
      plan_type: {
        free: '免費',
        pro: "專業",
        enterprise: '企業',
        team: '團隊',
        unlimited: '無限制'
      }
    },
    password: {
      password: '密碼',
      old: '舊密碼',
      new: '新密碼',
      confirm: '確認密碼',
      not_match: '密碼不相符'
    },
    detailed_info: {
      history: '歷史數據',
      trend_analysis: '趨勢分析',
      note: '注釋',
      references: '參考資料',
      content: {
        anomalies: "在 {date}，您的 {vital_sign} 記錄有異常情況",
      },
    },
    no_data: '沒有數據',
    help_content: {
      disclaimer: {
        title: "免責聲明",
        p1: "本系統是以攝像頭為基礎的非接觸式健康監測方案，可測量生命體徵如心率、呼吸頻率、血壓和血氧飽和度等。 捕捉影像只會作計算之用，並不會存儲任何影片片段。",
        p2: "WVS適合不需要持續或密集健康監測的個人用戶，且不能用作測量生命體徵的唯一方式或取代與醫學專家的諮詢。系統的生命體徵數據不適用於醫療目的， 如果您有任何進一步的查詢，請聯繫專業的醫療人員。"
      },
      setup: {
        title: "設置指南",
        dos: {
          title: "掃描進行時，請...",
          p1: "爲了更好的結果和使用體驗，請按以下指示完成掃描：",
          1: "將攝像頭放置於視線水平附近",
          2: "不要搖晃設備",
          3: "在有充足光源的地方進行掃描，確保光線（最好室内光源）平均照到臉上",
          4: "請避免背光",
          5: "請保持臉部在綠框中間及面向鏡頭",
          6: "請確保和鏡頭之間有合適的距離"
        },
        donts: {
          title: "掃描進行時，請不要...",
          1: "不要帶口罩",
          2: "不要帶帽子",
          3: "不要被任何東西遮擋臉部",
          4: "臉上不要有不均勻的光線 （例如陽光）",
          5: "不要談話",
          6: "身體不要大幅度移動",
          7: "不要把頭轉向旁邊"
        },
        low_accuracy: {
          title: "低準確率例子",
          1: "環境太暗或太光",
          2: "光線不均或背光",
          3: "佩戴口罩或帽子或被東西遮擋臉部",
          4: "沒有面向鏡頭",
          5: "與鏡頭的距離太遠",
          6: "與鏡頭的距離太近",
        },
      },
      operation: {
        title: "操作建議",
        ground_truth: {
          title: "請先使用接觸式設備測量數據",
          p1: "在激活 WVS 系統之前，用戶應先用接觸式脈搏血氧儀作量度，再將 WVS 系統的結果與接觸式脈搏血氧儀的結果進行比較，以得知結果的可參考性。",
          p2: "請按照以下步驟以打開和操作接觸式脈搏血氧儀：",
          1: "擠壓設備背面將其打開。",
          2: "將食指或無名指插入設備，顯示屏朝上。 慣用右手的使用者，請使用右手食指或無名指。 慣用左手的使用者，請使用左手食指或無名指。",
          3: "指尖應觸碰到設備內部的手指擋塊。 如果手指沒有正確定位，請從步驟 1 再次開始。",
          4: "該設備的顯示屏上將顯示您的生命體徵",
          p3: `有關如何使用接觸式設備的更詳細說明，請參閱<a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">此處</a>的設備手冊。`
        },
        scanning: {
          title: "健康掃描步驟",
          p1: "使用 WVS 掃描系統的步驟如下：",
          1: "掃描開始前，先選擇光源充足的地方舒適地就座，確保光線（最好是室内光源）平均地照到臉上。然後將電腦放置在穩定的平面上，避免設備搖晃。",
          2: "點擊 WVS 系統左側導覽列上的主頁按鈕（第一個圖標），再點擊右上角的綠色「開機」按鈕以啟動相機和面部掃描儀系統。 該頁面應顯示「正在啟動... 」，如下圖所示。如提示允許 WVS 使用您的相機，請點擊允許。",
          caption_2: "生命體徵掃描儀啟動中",
          3: "系統準備就緒後將啟動攝像頭並顯示鏡頭畫面，同時顯示一個綠色框代表檢測到的面部範圍。請確保身體和鏡頭之間有合適的距離，保持臉部在綠框中間及面向鏡頭。",
          caption_3: "系統已啟動",
          4: "點擊綠色圓圈「開始」按鈕以開始掃描程序。 在開始掃描之前，您需要遵循掃描儀中顯示的條件。 滿足條件後，屏幕會出現三秒倒計時，表示掃描即將開始。",
          caption_4: "掃描啟動條件",
          5: "掃描進行時請跟隨綠色框下方綠色圓圈中出現的呼吸指示調整呼吸。 呼吸指示將在「吸氣」和「呼氣」之間切換，如下圖所示，您可從掃描進度圓圈中看到剩餘所需的時間。",
          note_5: "注意：如呼吸速度與指示不吻合，掃描不會檢測到錯誤，但仍建議跟隨呼吸指示以正常靜息速度呼吸。",
          caption_5: "掃描開始",
          6: "掃描會在30秒內完成，並在頁面上顯示生命體徵結果。請等待掃描完成以查看結果。",
          caption_6: "基本生命體徵",
          7: "若您想瀏覽詳細的掃描報告，請點擊導覽列上的第二個圖示前往數據板。",
          caption_7: "掃描結果概覽",
          laptop: "使用手提電腦",
          phone: "使用電話",
          pc: "使用桌上型電腦"
        }
      }
    },
    scan_tutorial: {
      title: "Vitals™ 掃描快速指南",
      dos: "請......",
      donts: "請不要......",
      step1: {
        title: "保持身體處於綠色邊框內",
        paragraph: "點擊開始按鈕後，屏幕上將出現綠色人形邊框。 邊框將指引您到正確的位置和距離使掃描更有效。 請確保您處於綠色邊框內。",
        do1: "將相機放置在視線水平附近",
        do2: "將臉部保持在邊框內並面向相機",
        do3: "確保您和相機之間距離適當",
        dont1: "不要坐在離設備太遠或太近的位置",
      },
      step2: {
        title: "確保相機平穩",
        paragraph: "相機搖晃可能會影響掃描的閱讀。請確保設備被放在平穩的位置並儘量減少移動。",
        do1: "將設備放在平穩的表面上",
        do2: "避免晃動設備",
        dont1: "不要談話",
        dont2: "身體不要大幅度移動 "
      },
      step3: {
        title: "在相機上顯示整張臉",
        paragraph: "確保您的臉完整顯示在相機上。 拿走任何會遮擋臉部的東西。",
        do1: "面向相機",
        do2: "確保您的臉沒有被帽子、連帽衫、口罩、頭髮等遮住。",
        dont1: "不要戴任何會遮住臉的東西（帽子、連帽衫、口罩）",
        dont2: "不要側過臉"
      },
      step4: {
        title: "E確保臉上的光線均勻充足",
        paragraph: "不均勻的光線可能會影響閱讀的準確性。 建議使用室內照明以更容易確保照明正確。",
        do1: "選擇光線充足的區域，確保光線（最好是室內光線）均勻地照射在臉上",
        dont1: "臉上不要有不均勻的光線（例如：陽光）",
        dont2: "背光"
      }
    },
    health_report: {
      title: "個人健康報告",
      excellent: "出色",
      poor: "不佳",
      age: "年齡",
      sex: "性別",
      email: "電郵",
      phone_number: "電話號碼",
      female: "女",
      male: "男",
      height: "身高",
      weight: "體重",
      bmi: "BMI",
      measured: "如何量度？",
      general_wellness: "綜合健康分數",
      stress_level: "壓力水平",
      facial_skin: {
        young: "年輕",
        healthy: "健康",
        average: "正常",
        mature: "成熟",
        excellent: "极佳",
        fair: "一般",
        need_improvement: "有待改善"
      },
      stress: {
        high: "高",
        medium: "中等",
        low: "低",
      },
      wellness: {
        title: "整體健康",
        generalWellness: {
          title: "綜合健康",
          definition: "綜合健康反映您的整體健康狀況。",
          range: "正常的綜合健康分數為80-100",
          result: "你的綜合健康分數是{value}",
          measured: "綜合健康分數是通過核心生命體徵和精神壓力水平的加權分數來計算的。"
        },
        stress: {
          title: "壓力水平",
          definition: "壓力是一種情緒或身體緊張的感覺。 它可能來自任何讓你感到沮喪、憤怒或緊張的事件或想法。",
          range: "正常的靜息壓力等級為 0-2。",
          result: "你的壓力水平等級為{value}.",
          measured: "壓力是根據您的心率變異度 (HRV) 來衡量的，心率變異度是您心臟每次波動的頻率。 較高的心率變異度通常表示壓力較小，因為您的心臟能夠更好地應對各種壓力來源。"
        }
      },
      facial: {
        title: "面部",
        facialSkinAge: {
          title: "面部皮膚年齡",
          definition: "面部皮膚年齡是指根據您的皮膚狀況來估計的皮膚年齡。 結果可能受到不同情況的影響，例如化妝品/皮膚護理的使用、睡眠不足等。",
          range: "越年輕越好！",
          result: "您一直都年輕迷人！",
          measured: "面部皮膚年齡是通過使用機器學習演算法以分析您的面部狀況。"
        }
      },
      respiratory: {
        title: "呼吸系統",
        respiratory_rate: {
          title: "呼吸率",
          definition: "呼吸頻率為一個人每分鐘呼吸的次數。 呼吸頻率可能會隨著發燒、疾病、壓力和其他醫療狀況而上升。",
          range: "成年人靜息時正常呼吸頻率為每分鐘 12 至 16 次",
          result: "您的呼吸頻率為每分鐘 {value}次。"
        },
        spo2: {
          title: "血氧飽和度",
          definition: "血氧飽和度量度血紅細胞從肺部攜帶到身體其他部位的氧氣量。 低血氧症的常見症狀包括頭痛、心率加快、氣短等。",
          range: "大多數人的血氧飽和度為95% 或以上。",
          result: "你的血氧飽和度為 {value}%."
        }
      },
      cardiovascular: {
        title: "心血管系統",
        heartRate: {
          title: "心率",
          definition: "心率是每分鐘心臟跳動的次數 (bpm)。 高心率可能是由荷爾蒙問題、焦慮、大量攝入咖啡因/酒精、貧血等引起的。",
          range: "正常的靜息心率範圍為每分鐘60-100次。",
          result: "你的心率為每分鐘{value}次",
        },
        hrv_sdnn: {
          title: "心率變異度SDNN值",
          definition: "心率變異度是每次心跳之間時間變化的量度。 SDNN 是正常竇性搏動的 IBI 的標準偏差，以毫秒(ms) 為單位。低心率變異度可能是由睡眠不足的壓力造成的。",
          range: "100毫秒以上的心率變異度SDNN 是健康的。",
          result: "您的心率變異度 SDNN 為 {value} 毫秒。"
        },
        hrv_ibi: {
          title: "心率變異度IBI值",
          definition: "心率變異度是每次心跳之間時間變化的量度。 IBI 是心臟的搏動間期。 在心臟功能正常的情況下，每個 IBI 值會隨心跳改變。",
          range: "典型的心率變異度 IBI 值範圍為 500-1300 毫秒。",
          result: "您的心率變異性 IBI 為 {value} 毫秒。",
        },
        systolic_bp: {
          title: "收縮壓",
          definition: "收縮壓是由心肌收縮引起的肱動脈的峰值壓力。 低血壓可能是由脫水、懷孕、休克、內分泌失調等引起的。",
          range: "理想的收縮壓在 90-120mmHg 之間。",
          result: "您的收縮壓為 {value} mmHg。",
        },
        diastolic_bp: {
          title: "舒張壓",
          definition: "舒張壓是當您的心肌放鬆時您的肱動脈的壓力量。 高血壓可能是由不健康的生活方式或某些健康狀況引起的，例如糖尿病和肥胖。",
          range: "理想的舒張壓在 60-80 mmHg 之間。",
          result: "您的舒張壓為 {value} mmHg。"
        },
      },
      personal_health_goal: {
        title: "Personal Health Goal",
        gain_muscle: {
          title: "Gain Muscle",
          definition: "If your goal is to gain msucle, you should focus on a high protein and moderate carbohydrate diet to compliment your exercise regime. Each meal should contain at least 40g of lean protein to ensure your body is optimized for muscle recovery post workout."
        },
        lose_weight: {
          title: "Lose Weight",
          definition: "If your goal is to lose weight, you will need to consume fewer calories than you burn each day. When this happens, your body uses stored energy (i.e. fat) to make up for the calorie deficit. We recommend a diet that has moderate lean protein and low carbohydrate to achieve this outcome."
        }
      },
      recommend: {
        young: {
          title: "Young+ ",
          subtitle: "骨胶原洋甘菊乌龙橙茶",
          ingredients: "成分：乌龙茶, 洋甘菊, 荨麻, 橙皮",
          description: "美颜配方注入草饲料牛胶原蛋白粉，助保持皮肤及骨骼健康，促进消化",
          image: "./../assets/young.jpg"
        },
        sooth: {
          title: "Sooth ",
          subtitle: "罗汉果菊花夏枯草",
          ingredients: "成分：罗汉果, 夏枯草, 菊花",
          note: "不含咖啡因",
          description: "清热润肺，滋养及平衡内脏功能",
          image: "./../assets/sooth.jpg"
        },
        sleep: {
          title: "Sleep ",
          subtitle: "洋甘菊南非国宝茶",
          ingredients: "成分：南非国宝茶, 茴香, 洋甘菊",
          note: "不含咖啡因",
          description: "帮助睡眠，舒缓紧张，抗氧化",
          image: "./../assets/sleep.jpg"
        },
        energy: {
          title: "Energy ",
          subtitle: "姜黄力量",
          ingredients: "成分：红茶, 姜黄, 肉桂, 黑胡椒,可可果皮",
          description: "提升体力及免疫力，抗炎，促进血液循环",
          image: "./../assets/energy.jpg"
        },
        sunset: {
          title: "Sunset ",
          subtitle: "洛神花橙茶",
          ingredients: "成分：洛神花, 玫瑰果, 甘草, 香茅",
          note: "不含咖啡因",
          description: "混合优质草本与洛神花，帮助身体放松，舒缓压力, 高度抗氧化",
          image: "./../assets/sunset.jpg"
        },
        lify_banner: "./assets/lify-banner-tc.jpg",
        gain_muscle: {
          title: "Gain Muscle",
          description:
            "If your goal is to gain muscle, you should focus on a high protein and moderate carbohydrate diet to compliment your exercise regime. Each meal should contain at least 40g of lean protein to ensure your body is optimized for muscle recovery post workout.<br/><br/>Please refer to your HEALTH REPORT for the recommended weekly meal plan menu.",
          diet: [
            'Perfect for bulking mass with optimal macros',
            'Average Calories Per Meal > 550 kcal'
          ],
          points: [
            'High Protein',
            'Moderate Fats',
            'High Carbs',
          ],
          promotion: "At FITTERY, we recommend our Bulk Up meal plan program (<a href='https://fittery.com.hk/products/bulk-up'>https://fittery.com.hk/products/bulk-up</a>) which provides on average 50g of protein per meal. Remember to use special promo code HEALTHY25 to enjoy 25% off!",
          image: "../assets/gain-muscle.svg",
        },
        lose_weight: {
          title: "Lose Weight",
          description:
            "If your goal is to lose weight, you will need to consume fewer calories than you burn each day. When this happens, your body uses stored energy (i.e. fat) to make up for the calorie deficit. We recommend a diet that has moderate lean protein and low carbohydrate to achieve this outcome.<br/></br>Please refer to your HEALTH REPORT for the recommended weekly meal plan menu.",
          diet: [
            'Perfect for losing weight on an optimised diet',
            'Average Calories Per Meal < 400 kcal'
          ],
          points: [
            'Moderate Protein',
            'Low Fats',
            'Low Carbs'
          ],
          promotion: "At FITTERY, we recommend either our Low Carb or Light N Easy meal plan program (<a href='https://fittery.com.hk/pages/signature-plan-overview'>https://fittery.com.hk/pages/signature-plan-overview</a>) with each meal only approx. 300-400 calories. Remember to use special promo code HEALTHY25 to enjoy 25% off!",
          image: "../assets/lose-weight.svg",
        }
      },

      disclaimer: {
        title: "免責聲明",
        p1: "以攝像頭為基礎的健康監測方案設計用於非接觸式測量健康生命體徵，例如心率、呼吸頻率、血壓和 O2 飽和度。 視頻片段將通過設備的攝像頭捕捉，但不會存儲任何視頻片段。",
        p2: "該設備不能用作個人健康生命體徵測量的唯一方法，也不能取代與醫學專家的諮詢。 而且測量不用於醫療目的。 如果您有任何進一步的健康查詢，請立即聯繫醫療保健專業人員或其他醫學專家。",
        powered_by: '由',
        powered_by_2: '提供技術支持'
      },
      wait: "<br>謝謝!<br>請稍等，您的報告正在生成中⋯⋯<br>您亦同時可以在智能平板上找到您的專屬建議！",
      finish: [
        '<br>您的報告已準備完成！<br>您可以檢查您的下載檔案或',
        '按此',
        '如果您未能找到已下載檔案'
      ],
    },
    start_content: {
      title: "A.I.智能养生体验",
      subtitle: "想了解哪款养生茶最适合你？立刻体验智能养生分析，让A.I.话您知！",
      stress: "膚齡",
      wellness: "壓力水平",
      report: "健康指數",
      tea: "專屬養生茶",
      terms_condition: {
        title: "请阅读条款及细则",
        content: [
          "此体验是以摄像镜头为基础的非接触式健康监测方案，捕捉之影像及客户所提供之数据只会作即时计算之用，并不会存储任何人脸影像片段及相关数据。",
          "此体验提供的资讯、功能、特色及服务仅作为资讯服务和一般性指引，并未必涵盖与 阁下特定情况相关的所有事宜。在提供健康分析时，我们并非作为诊所或专业执业医生从事业务、进行管理或维持经营，因此我们对 阁下并无相应的护理责任。在任何情况下，此体验均不拟作为个人或医疗诊断、建议或治疗、医疗服务或用作医疗设备。",
          "此体验所推荐之养生饮品并非用作治疗、诊断或预防疾病用途。",
          "阁下作出任何与健康有关之决定，均应咨询医生或医疗护理专业人士。我们对该等资讯服务的输出结果概不作出任何保证，依赖此健康分析提供的任何资讯完全由 阁下自行承担风险。",
          "服用任何处方药时，请谨慎使用本产品（如果有的话）。我们坚持阁下在食用前咨询医疗保健专业人士。特别是如果阁下有任何健康状况、怀孕或哺乳或正在服用任何处方药，请在食用前咨询阁下的医生。",
          "如阁下对任何食物过敏，可参考提示牌上每款养生茶的成份，如有需要，请向我们的服务专员寻求协助。"
        ],
        agree: "* 按「開始」即表示您已清楚閱讀並同意上述條款及細則",
      },
      steps: [
        "阅读<br>条款及细则",
        "进行人脸影像<br>扫描",
        "查看适合你的<br>养生茶"
      ],
      warning: "如您想直接自行拣选喜爱的养生茶，欢迎使用点餐系统或向我们的服务专员寻求协助"
    },
    user_input: {
      title: "请输入下列信息，以作更准确的健康分析：",
      question: "出生性别",
    },
    quiz: [
      {
        question: "平常您会经常感到......",
        options: [
          "平静、放松",
          "压力大、疲累",
          "脾气暴躁",
          "全部皆非 ",
        ],
      },
      {
        question: "您的睡眠质量是... ",
        options: [
          "好而且不受干扰",
          "普遍还可以",
          "浅睡而且经常失眠",
        ],
      },
      {
        question: "您容易得伤风感冒吗？",
        options: [
          "是 (一月個一次)",
          "有时候 (三个月一次)",
          "甚少 (一年一次)"],
      },
    ],
    scan_result: {
      title: "专属您的健康分析",
      skin_age: "肤质",
      wellness: "健康指数",
      stress: "压力水平",
      skip: "查看適合您的養生茶及飲食建議 >",
      email: "請輸入您的電郵，以獲取完整版的健康分析報告，<br>及LIFY Wellness的特選優惠：",
      email_placeholder: "輸入電郵",
      back: "返回健康分析",
      lify_recommendation: "適合您的養生茶",
      qr: "立即掃描二維碼<br>查看完整版的健康分析報告"
    },
  },
  "EN": {
    page: {
      dashboard: 'Dashboard',
      settings: 'Settings',
      help: 'Help',
      logout: 'Logout',
      coming_soon: 'Stay tuned!<br>We are Coming Soon...',
    },
    guest_signin: 'Continue as Guest',
    logout_prompt: 'Are you sure you want to log out?',
    instruction: {
      stay_still: 'Scanning in progress, stay still.',
      count_down1: 'Scanning starting in ',
      count_down2: ', stay still'
    },
    button: {
      mode: 'Mode',
      fever_percentage: 'Fever Percentage',
      fever_threshold: 'Threshold Temp.',
      start: 'START',
      stop: 'STOP',
      shutdown: 'SHUT DOWN',
      cancel: 'Cancel',
      ok: 'OK',
      all: 'ALL',
      power_on: 'POWER ON',
      full_screen: 'FULL SCREEN',
      save_settings: 'Save Settings',
      upgrade: 'Upgrade',
      change_password: 'Change Password',
      next: "Next",
      previous: "Previous",
      continue: 'CONTINUE',
      show_health_report: 'Show Health Report',
      clear_user_information: 'Clear User Info',
      send_to_email: 'Send to Email',
      rate_us: 'Rate Us!',
      submit: 'Submit',
      on: "ON",
      off: "OFF",
      start_again: "Start Over",
      back_to_homepage: "Back to Homepage",
      agree: "I have read and agree",
      disagree: "Disagree",
      immediately: 'Start Now'
    },
    labels: {
      sending: 'sending...',
      user_profile: 'User Profile',
      rate_us: 'Your feedback is valuable to us, please give us a rating!',
      poor: 'Poor',
      good: 'Good',
      dissatisfied: 'Dissatisfied',
      satisfied: 'Satisfied',
    },
    graph: {
      measurement: 'Measurement',
      time: 'Time',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      trend_line: 'Show Trend Line',
      excellent: 'Excellent',
      good: 'Good',
      poor: 'Poor'
    },
    mode: {
      normal: 'Normal',
      debug: 'Debug'
    },
    threshold: {
      auto: 'Auto'
    },
    message: {
      startup: 'System is OFF, press "POWER ON" to start.',
      loading: 'Loading',
      calibrating: 'Stay still, Calibrating ...',
      no_face_box: 'No Face Box ...',
      stopping: 'Stopping {system_short_name}...',
      confirm_shutdown: 'Are you sure you want to shutdown the system?',
      important_message: 'Important Message',
      emmisivity: '{short_name} v{version} is designed to work with FLIR A315 with emmisivity set to <span class="text-danger font-weight-bold">{emmisivity}</span>.',
      auto_sys_restart: 'Automatic System Restart',
      prevent_overheat: 'Restarting the system to prevent over-heating...',
      camera_error: 'Camera Error Detected',
      system_error: 'System Error Detected',
      camera_fatal_error: 'System automatically stopped due to camera error, please contact {contact}. Thank you.',
      system_fatal_error: 'System automatically stopped due to error, please contact {contact}. Thank you.',
      system_off: 'System is OFF.',
      tap_to_view: 'Tap to View',
      condition_check_failed: '(1) Please move into the pur ple human <br/>(2) stay still',
      coming_soon: 'Coming Soon',
      breath_in: 'In',
      breath_out: 'Out',
      bad_scan_message: 'Bad scan, please restart the scan',
      weak_signal_message: 'Weak signal',
      settings_updated: 'Settings updated',
      password_updated: 'Password updated',
      password_update_failed: 'Failed to change password, please check your password',
      fill_profile: 'Please fill in your company profile',
      conditions: {
        lighting: "Lighting",
        distance: "Distance",
        centered: "Position",
        movement: "Hold Still",
        server_ready: "System Ready"
      },
      subscribe: "Subscribe to see this content!",
      scan_quality: "Scan Quality",
      email: 'Email sent to',
      feedback: "Feedback received, Thank You!! Have a nice day~",
      final_check: "Look straight at the camera.<br>The scan will begin in {second} seconds.",
      scanning_progress: {
        title: "While scanning is in progress:",
        content: [
          "Stay still",
          "Answer the question below",
          "Maintain regular breathing",
          "Ensure face is fully visible to the camera"
        ]
      },
      camera_preparation: "We are downloading some models, please ensure your face is in front of the camera!",
      loading_wait: "Loading, please wait!",
      wait_result: "Analyzing result... Please wait...",
    },
    disclaimer: {
      disclaimer1: "Disclaimer: The software does not store any personal data.",
    },
    copyright: "\u00A9 2021-2023 PanopticAI Ltd. All Rights Reserved.",
    last_scan: 'Last Scan',
    last_update: 'Last Updated',
    vs_descriptions: {
      bloodPressure: "The normal blood pressure for an adult is below 120/80 mmHg.",
      bmi: "The normal BMI for an adult is 18.5-24.9.",
      respiratoryRate: "The normal breathing rate for an adult is 6-24 bpm.",
      generalRisk: "Risk of getting cardiovascular diseases, the smaller the better.",
      covidRisk: "Risk of getting covid-19, the smaller the better.",
      heartRate: "The normal resting heart rate for an adult is between 60-100 bpm.",
      hrvSdnn: "The normal HRV (SDNN) for an adult is above ~20ms",
      //      hrvSdnn: "The normal HRV (SDNN) for an adult is 68-219 ms throughout the whole day",
      hrvIbi: "The normal resting IBI for an adult is between 600-1000 ms.",
      spo2: "The normal O2 saturation for an adult is 95-100 %.",
      stress: "The normal resting stress rating is from 0-2.",
      temperature: "The normal body temperature for an adult is around 98.6°F (37°C).",
      facialSkinAge: "You are always young & charming ;)",
    },
    option: {
      yes: 'Yes',
      no: 'No',
      logout_yes: 'Yes',
      logout_no: 'No',
    },
    user: {
      supervisor: 'Supervisor',
      guest: 'Guest',
    },
    company_info: {
      company_information: "Company Information",
      company_id: "Company Id",
      project_id: "Project Id",
      site_id: "Site Id",
      location: "Site Location",
      subscription_plan: 'Subscription Plan',
      valid_until: 'Valid Until',
    },
    user_info: {
      user_id: 'User Id',
      profile_image: 'Profile Image',
      personal_information: 'Personal Information',
      subscription_plan: 'Subscription Plan',
      valid_until: 'Valid Until',
      name: 'Name',
      nickname: 'Nickname',
      birthday: 'Birthday',
      height: 'Height (cm)',
      weight: 'Weight (kg)',
      gender: 'Gender at birth',
      male: 'Male',
      female: 'Female',
      unit_system: 'Unit System',
      country: 'Countries',
      countryOptions: {
        hongkong: "Hong Kong, China",
        china: "Mainland China",
      },
      metric: 'Metric',
      imperial: 'Imperial',
      medical_history: 'Medical History',
      smoking: 'Do you smoke?',
      hypertension: 'Do you have hypertension?',
      bp_medication: 'Are you taking blood pressure medication?',
      diabetic: 'Are you diabetic?',
      heart_disease: 'Do you have heart disease?',
      depression: 'Do you have depression?',
      department: 'Department',
      type_1: 'Type 1',
      type_2: 'Type 2',
      plan_type: {
        free: 'Free',
        pro: "Pro",
        enterprise: 'Enterprise',
        team: 'Team',
        unlimited: 'Unlimited'
      }
    },
    password: {
      password: 'Password',
      old: 'Old Password',
      new: 'New Password',
      confirm: 'Confirm Password',
      not_match: 'Password does not match'
    },
    detailed_info: {
      history: 'History',
      trend_analysis: 'Trend Analysis',
      note: 'Note',
      references: 'References',
    },
    no_data: 'No Data',
    help_content: {
      disclaimer: {
        title: "Disclaimer",
        p1: "The camera-based health and wellness monitoring solution is designed for contactless measurement \
        of health vital signs such as heart rate, breathing rate, blood pressure, and O2 saturation. Video \
        footage is captured through a device's camera, but no video footage is stored.",
        p2: "WVS is to be used by individuals who do not require constant or intensive health monitoring. The device \
        is not to be used as the only method for health vital signs measurement of the individuals and is not intended \
        to replace consultations with medical experts. The measurements are not served for medical purposes. Should you \
        have any further health enquiries, please contact health care professionals or other medical experts immediately."
      },
      setup: {
        title: "Set-up Guidelines",
        dos: {
          title: "Dos",
          p1: "For the best results, please do the following for the scanning:",
          1: "Put the camera near your eye level",
          2: "Prevent shaking the device ",
          3: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
          4: "Avoid backlighting ",
          5: "Keep your face well within the box and facing the camera",
          6: "Ensure suitable distance between you and the camera"
        },
        donts: {
          title: "Dont's",
          1: "Do not wear a mask",
          2: "Do not wear a hat",
          3: "Do not wear anything that will cover your face",
          4: "Do not have uneven light on you face (e.g. sunlight)",
          5: "Do not talk",
          6: "Do not make big movements",
          7: "Do not turn your head aside",
        },
        low_accuracy: {
          title: "Low Accuracy Examples",
          1: "The area is too bright or too dark",
          2: "Uneven lighting or backlighting",
          3: "The user is wearing a hat or having something cover one’s face",
          4: "The user is not facing the camera",
          5: "The distance between the user and the camera is too far",
          6: "The distance between the user and the camera is too close"
        }
      },
      operation: {
        title: "Operation",
        ground_truth: {
          title: "Measure Ground Truth with Contact-Based Device",
          p1: "The following steps should be taken before activating the WVS system so the user can compare the results from the WVS system with the results from the contact-based pulse oximeter.",
          p2: "The contact-based pulse oximeter is off until a finger is placed in it. To turn on and operate device, follow the following steps:",
          caption: `Contact-Based Device Scanning`,
          1: "Squeeze the back of the device to open it.",
          2: "Insert the index finger or ring finger into the device with the display facing up. For a left-handed person, use the left-hand index finger or ring finger. For a right-handed person, use the right-hand index finger or ring finger.",
          3: "The tip of the finger should reach the finger stop inside of the device. If the finger is not correctly positioned, repeat from Step 1.",
          4: "The device will now show your vital signs on the display.",
          p3: `For more detailed instructions on how to use the contact-based device, please refer to the manual for the device <a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">here</a>.<br>`
        },
        scanning: {
          title: "Using The Health Scanning System",
          p1: "The following steps are done to use the WVS scanning system:",
          1: "Sit comfortably in a well-lit area before scanning. Ensure the light (preferably indoor light) is evenly shed on your face. Then place the computer on a sturdy surface to prevent shaking.",
          2: `Click on the home button (the first icon) on the Navigation Bar on the left side of the WVS system. Then click on the green '<i>Power On</i>' 
          on the top right corner to start up the camera and face scanner system. The page will then display '<i>Starting up …</i>' as shown in Figure 2 below. Click allow, if prompted to enable WVS to use your camera.`,
          caption_2: "Starting up the vital sign scanner",
          3: "When the system is ready, the system will activate your device’s camera and show it in the <i>Homepage</i> along with a green box indicating your detected face. Keep your face in the center of the green frame and facing the lens while ensuring that there is an appropriate distance between your body and the camera.",
          caption_3: "Powered up system",
          4: `Click on the green circle "<i>Start</i>" button to begin the scanning process. Before beginning the scan, you will need to follow pass the <i>Scanning 
          conditions</i> shown in the face scanner and stay still while the scanner is calibrating. Once the scanning conditions are met, a three second 
          countdown will appear in the middle of the screen to show that the scan is starting.`,
          caption_4: "Conditions for scanning",
          5: `Follow the <i>Breathing guide</i> instructions that appears in the green circle throughout the scanning process. The breathing guide will change 
          between '<i>In</i>' and '<i>Out</i>' to indicate breathing in and breathing out, as shown in Figure 12 and Figure 13. The <i>Scanning progress</i> circle 
          can be seen in the scanner too. The <i>Scanning progress</i> circle indicates the remaining time till the scanning procedure is completed. `,
          caption_5: "Scanning started",
          note_5: `Note: Users are reminded to follow the in and out guide to breath at normal resting speed, although no error in the scan will be detected if the 
          breathing in and out are not matched precisely with the visual indicator.`,
          6: `The scanning progress will complete within 30 seconds, and the vital sign results will be displayed while scanning is 
          being completed. Please wait until the scan is complete to see the results. `,
          caption_6: "Basic vital signs",
          7: `To see the detailed scanning result, go to the "<i>Dashboard</i>" by clicking on the <i>Navigation bar</i>.`,
          caption_7: "Scanning results overview",
          laptop: "Using Laptop",
          phone: "Using Phone",
          pc: "Usign PC"
        }
      }

    },
    scan_tutorial: {
      title: "Quick Guide for Scanning on Vitals",
      dos: "Dos:",
      donts: "Don'ts:",
      step1: {
        title: "Position Yourself Inside The Green Border",
        paragraph: "Once you click on the start button, a green human border will appear on your screen. This will guide you for a correct position and distance to make the scanning more effective. Make sure you are positioning yourself to be inside the green border.",
        do1: "Put the camera near your eye level",
        do2: "Keep your face well within the border and facing the camera",
        do3: "Ensure suitable distance between you and the camera",
        dont1: "Do not sit too far away from or too close to the device",
      },
      step2: {
        title: "Ensure Camera is Steady",
        paragraph: "Shaky camera may affect the reading of the scanning. Please make sure that the device is placed on a steady place and do minimum movement.",
        do1: "Place device on steady surface",
        do2: "Prevent shaking the device",
        dont1: "Do not talk",
        dont2: "Do not make big movements "
      },
      step3: {
        title: "Whole Face Displayed on Camera",
        paragraph: "Make sure that your face is fully displayed on camera. Remove anything covering your face.",
        do1: "Face the camera",
        do2: "Make sure your face is not covered such as with hat, hoodie, mask, hair.",
        dont1: "Do not wear anything that cover your face (hat, hoodie, mask)",
        dont2: "Do not turn your face sideways"
      },
      step4: {
        title: "Ensure Even and Sufficient Lighthing on Your Face",
        paragraph: "Uneven lighting may affect the correctness of your reading. Indoor lighting is recommended to ensure correct lighting more easily",
        do1: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
        dont1: "Do not have uneven light on you face (e.g. sunlight)",
        dont2: "Backlighting"
      }
    },
    health_report: {
      title: "PERSONAL HEALTH REPORT",
      excellent: "Excellent",
      poor: "Poor",
      age: "Age",
      sex: "Sex",
      email: "Email",
      phone_number: "Phone Number",
      female: "Female",
      male: "Male",
      height: "Height",
      weight: "Weight",
      bmi: "BMI",
      measured: "How is it measured?",
      general_wellness: "General Wellness",
      stress_level: "Stress Level",
      facial_skin: {
        young: "Young",
        healthy: "Healthy",
        average: "Average",
        mature: "Mature",
        excellent: "Excellent",
        fair: "Fair",
        need_improvement: "Room for Improvement"
      },
      stress: {
        high: "High",
        medium: "Medium",
        low: "Low"
      },
      wellness: {
        title: "Wellness",
        generalWellness: {
          title: "General Wellness",
          definition: "General wellness score is calculated through a weighted score of the core vital signs and mental stress level.",
          range: "The normal general wellness score ranges from 80-100.",
          result: "Your general wellness score is {value}.",
          measured: "General wellness is calculated from a weighted score of the measured vital signs and the user’s profile information. "
        },
        stress: {
          title: "Stress Level",
          definition: "Stress level is a feeling of emotional or physical tension. High level of stress  may be caused by any event or thought that makes you feel frustrated, angry, or nervous.",
          range: "The normal resting stress rating is from 0-2.",
          result: "Your stress level is {value}.",
          measured: "Stress is measured from your heart rate variability (HRV), the beat-to-beat fluctuations in your heart. A higher HRV generally indicates less stress, as your heart is able to better respond to various stressors. "
        }
      },
      facial: {
        title: "Facial",
        facialSkinAge: {
          title: "Facial Skin Age",
          definition: "Facial skin is particularly thin and the most visible part of the body. Like all skin, face skin serves as an effective barrier against the external environment.",
          range: "The younger the better!",
          result: "You are young and charming!",
          measured: "Facial skin age is calculated by using a machine learning model to analyse your face. "
        }
      },
      respiratory: {
        title: "Respiratory",
        respiratory_rate: {
          title: "Respiratory Rate",
          definition: "Respiratory rate is the number of breaths a person takes per minute. High level of respiratory rates may be caused by exercise, asthma, anxiety, etc. Low level of respiratory rate may be caused by side effects from medications or an underlying medical problem.",
          range: "Normal respiration rates for an adult person at rest range from 12 to 25 breaths per minute.",
          result: "Your respiratory rate is {value} bpm."
        },
        spo2: {
          title: "Oxygen Saturation",
          definition: "Oxygen saturation is a measure of how much oxygen the red blood cells are carrying from the lungs to the rest of the body. Low level of oxygen saturation (hypoxemia) may be caused by an underlying illness that affects blood flow or breathing.",
          range: "Most people will read an SpO<sub>2</sub> of 95% or above.",
          result: "Your SpO<sub>2</sub> is {value}%."
        }
      },
      cardiovascular: {
        title: "Cardiovascular",
        heartRate: {
          title: "Heart Rate",
          definition: "Heart rate is the number of beats of the heart per minute (bpm). High level of heart rate  may be caused by anxiety, heavy caffeine/alcohol consumption, etc.",
          range: "The normal resting heart rates ranges from 60-100 bpm",
          result: "Your heart rate is {value} bpm.",
        },
        hrv_sdnn: {
          title: "Heart Rate Variability SDNN",
          definition: "HRV is a measure of the variation in time between each heartbeat. SDNN is the standard deviation of the interval between normal heartbeats. Low level of heart rate variability may be caused by stress of lack of sleep.",
          range: "The heart rate variability SDNN above 100 ms are healthy.",
          result: "Your heart rate variability SDNN is {value} ms."
        },
        hrv_ibi: {
          title: "Heart Rate Variability IBI",
          definition: "HRV is a measure of the variation in time between each heartbeat. IBI is the interbeat interval. In normal heart function, each IBI value varies from beat to beat.",
          range: "Typical heart rate variability IBI values range from 500-1300 ms.",
          result: "Your heart rate variability ibi is {value} ms.",
        },
        systolic_bp: {
          title: "Systolic Blood Pressure",
          definition: "Systolic blood pressure is the peak pressure in your brachial arteries that results from the contraction of your heart muscle. Low level of blood pressure may be caused by dehydration, pregnancy, shock, endocrine disorders, etc.",
          range: "The ideal systolic blood pressure is between 90-120mmHg.",
          result: "Your systolic blood presusre is {value} mmHg.",
        },
        diastolic_bp: {
          title: "Diastolic Blood Pressure",
          definition: "Diastolic blood pressure is the amount of pressure in your brachial arteries when your heart muscle is relaxed. High blood pressure may be caused by unhealthy lifestyle or certain health conditions, such as diabetes and having obesity.",
          range: "The ideal diastolic blood pressure is between 60-80 mmHg.",
          result: "Your diastolic blood pressure is {value} mmHg."
        }
      },
      personal_health_goal: {
        title: "Personal Health Goal",
        gain_muscle: {
          title: "Gain Muscle",
          definition: "If your goal is to gain msucle, you should focus on a high protein and moderate carbohydrate diet to compliment your exercise regime. Each meal should contain at least 40g of lean protein to ensure your body is optimized for muscle recovery post workout."
        },
        lose_weight: {
          title: "Lose Weight",
          definition: "If your goal is to lose weight, you will need to consume fewer calories than you burn each day. When this happens, your body uses stored energy (i.e. fat) to make up for the calorie deficit. We recommend a diet that has moderate lean protein and low carbohydrate to achieve this outcome."
        }
      },
      recommend: {
        young: {
          title: "Young+",
          subtitle: "<br>Skin & Body Recovery Support",
          ingredients: "",
          description: "A delicious blend of oolong tea and herbal ingredients dense in vitamins, minerals to support your healthy skin and bones.",
          image: "./assets/young.jpg"
        },
        sooth: {
          title: "Sooth",
          subtitle: "<br>Monk Fruit Prunella Blend",
          note: "Caffeine-free",
          ingredients: "",
          description: "A soothing blend to cool the body and help nourish and stabilise numerous internal organs.",
          image: "./assets/sooth.jpg"
        },
        sleep: {
          title: "Sleep",
          subtitle: "<br>Quality Sleep with Rooibos Chamomile",
          note: "Caffeine-free",
          ingredients: "",
          description: "A comforting blend of caffeine-free Rooibos and calming Chamomile, best to be taken in the evening for an enhanced restful sleep.",
          image: "./assets/sleep.jpg"
        },
        energy: {
          title: "Energy",
          subtitle: "<br>Turmeric Power Up Body Digestive Support",
          ingredients: "",
          description: "An uplifting blend of black tea with herbs and spices that are traditionally used for reducing inflammation, improving circulation and giving you an energy boost.",
          image: "./assets/energy.jpg"
        },
        sunset: {
          title: "Sunset",
          subtitle: "<br>Hibiscus Stress Relief (Blood Orange)",
          note: "Caffeine-free",
          ingredients: "",
          description: "A declicious blend of premium herbs with hibiscus flowers to create a tea high in antioxidants to renew and leave your bod feeling fresh.",
          image: "./assets/sunset.jpg"
        },
        lify_banner: "./assets/lify-banner-en.jpg",
        gain_muscle: {
          title: "Gain Muscle",
          description:
            "If your goal is to gain muscle, you should focus on a high protein and moderate carbohydrate diet to compliment your exercise regime. Each meal should contain at least 40g of lean protein to ensure your body is optimized for muscle recovery post workout.<br/><br/>Please refer to your HEALTH REPORT for the recommended weekly meal plan menu.",
          diet: [
            'Perfect for bulking mass with optimal macros',
            'Average Calories Per Meal > 550 kcal'
          ],
          points: [
            'High Protein',
            'Moderate Fats',
            'High Carbs',
          ],
          promotion: "At FITTERY, we recommend our Bulk Up meal plan program (<a href='https://fittery.com.hk/products/bulk-up'>https://fittery.com.hk/products/bulk-up</a>) which provides on average 50g of protein per meal. Remember to use special promo code HEALTHY25 to enjoy 25% off!",
          image: "./assets/gain-muscle.svg",
        },
        lose_weight: {
          title: "Lose Weight",
          description:
            "If your goal is to lose weight, you will need to consume fewer calories than you burn each day. When this happens, your body uses stored energy (i.e. fat) to make up for the calorie deficit. We recommend a diet that has moderate lean protein and low carbohydrate to achieve this outcome.<br/></br>Please refer to your HEALTH REPORT for the recommended weekly meal plan menu.",
          diet: [
            'Perfect for losing weight on an optimised diet',
            'Average Calories Per Meal < 400 kcal'
          ],
          points: [
            'Moderate Protein',
            'Low Fats',
            'Low Carbs'
          ],
          promotion: "At FITTERY, we recommend either our Low Carb or Light N Easy meal plan program (<a href='https://fittery.com.hk/pages/signature-plan-overview'>https://fittery.com.hk/pages/signature-plan-overview</a>) with each meal only approx. 300-400 calories. Remember to use special promo code HEALTHY25 to enjoy 25% off!",
          image: "./assets/lose-weight.svg",
        }
      },
      disclaimer: {
        title: "Disclaimer",
        p1: "The camera-based health and wellness monitoring solution is designed for contactless measurement of health vital signs such as heart rate and O<sub>2</sub> saturation. Video footage is captured through a device's camera, but no video footage is stored.",
        p2: "The device is not to be used as the only method for health vital signs measurement of the individuals and is not intended to replace conulstations with medical experts. The measurements are not served for medical purposes. Should you have any further health enquiries, please contact health care professionals or other medical experts immediately.",
        powered_by: 'Powered by ',
        powered_by_2: ""
      },
      wait: "<br>Thanks!<br>Please wait while your report is being generated…<br>Meanwhile, you may find your personalised recommendations on the iPad!",
      finish: [
        '<br>Your report is ready!<br>Check your downloaded file or ',
        'click here',
        ' if the you cannot find the downloaded file'
      ],
    },
    start_content: {
      title: "A.I. Wellness Analysis",
      subtitle: "Want to know which wellness tea is best for you?<br>Get your A.I. Wellness Analysis now!",
      stress: "Stress Level",
      wellness: "General Wellness",
      report: "Health Report",
      tea: "Recommended tea",
      terms_condition: {
        title: "Terms and Conditions",
        content: [
          'This A.I Wellness Analysis is a camera-based, non-contact health monitoring solution that captures images and user provided health data for instant calculation purposes only, and does not store any face images and relevant data.',
          'The information, functions, features and services provided in this Analysis are intended as an information service and general guide only and may not cover all matters relating to your particular situation. In providing the Analysis, we are not practicing, administering or maintaining a practice as a clinic or professional practitioner, and therefore we have no corresponding duty of care to you. In no event is this Analysis intended as a personal or medical diagnosis, advice or treatment, medical service or as a medical device.',
          'The wellness tea recommended in the A.I Wellness Analysis are not intended for treatment, medical diagnosis or prevention of diseases.',
          'You should consult a doctor or health care professional in making any health-related decisions. We do not guarantee the output of these information services and any information provided in reliance on this Health Analysis is entirely at your own risk.',
          'Use this product with caution, if at all, when taking any prescription medication. We insist that you consult a health care professional before consuming. Particularly if you have any medical conditions, are pregnant or breastfeeding or are taking any prescribed medications, please consult your physician before consuming',
          'If you have any food allergies, please refer to the ingredients of the wellness tea listed on the menu, or seek our service specialists for assistance if necessary.'
        ],
        agree: "* 按「開始」即表示您已清楚閱讀並同意上述條款及細則",
      },
      steps: [
        "Read the terms and conditions",
        "Start the facial scan",
        "View the best wellness tea for you"
      ],
      warning: "If you would like to choose your favourite tea directly, please use the ordering system or ask our service specialists for assistance."
    },
    user_input: {
      title: "Please enter the following information for a more accurate Health Analysis:",
      question: "Gender",
    },
    quiz: [
      {
        question: "Usually you feel......",
        options: [
          "Calm, relaxed",
          "Stressed, tired",
          "Quick to anger",
          "None of the above",
        ],
      },
      {
        question: "Your sleeping quality is...",
        options: [
          "Good and uninterrupted",
          "Basically fine",
          "Unsatisfactory with constant insomnia",
        ],
      },
      {
        question: "Do you catch cold or flu easily?",
        options: [
          "Yes (Once a month)",
          "Sometimes (Once in 3 months)",
          "Rarely (Once a year)"
        ],
      },
    ],
    scan_result: {
      title: "Your Health Analysis",
      skin_age: "Skin Texture",
      wellness: "Health Index",
      stress: "Stress Level",
      skip: "View the right tea for you >",
      email: "Please enter your email to receive the full version of the Health Analysis Report and LIFY Wellness special offer, and discover the right tea for you:",
      email_placeholder: "Enter email",
      back: "Back to Health Analysis",
      lify_recommendation: "The recommended tea for you",
      qr: "Scan the QR code now<br>to view the full version of the Health Analysis Report"
    },
  },
};

module.exports = locales;
