<template>
    <button 
        ref="button" 
        v-if="state == 'idle' && !showQuiz && !showModal"
        class="btn"
        @click="removeProfile"
    >
        {{ t('button.agree') }}
    </button>
</template>

<script>
import { ref, inject } from 'vue'
import { useI18n } from 'vue-i18n';
// import BIBOCircle from './breathing-circle.vue'
import VitalsConfig from '../../plugin/plugin_configuration.js'

export default {
    name: 'vital-sign-button',
    emits: ["startMeasurement", "removeProfile", "updateModal"],
    props: {
        radius: Number,
        enable: Object,
        showQuiz: Boolean,
        showModal: Boolean
    },
    setup() {
        const { t, locale } = useI18n();
        const {profileFilled, updateProfileFilled} = inject('profileFilled');

        return {
            profileFilled,
            updateProfileFilled,
            t, locale,
            vitalSign: ref(),
            state: ref('idle'),
        }
    },
    data() {
        return {
            top: ''
        }
    },
    watch: {
        timeLeft(newValue) {
            if (newValue === null) {
                this.progress = 0;
                this.state = "idle";
            } else {
                this.progress = Math.round(((this.totalTime - this.timeLeft) / this.totalTime)*100.0)
            }
        },
        enable(newValue) {
            if (newValue == undefined) {
                this.state = 'idle';
            }
        },
        start(val) {
            if (val) {
                setTimeout(() => {
                    this.getOffsetTop();
                }, 1000);
            }
        }
    },
    created() {
        this.totalTime = VitalsConfig.Plugin.scanTime;
    },
    methods: {
        removeProfile() {
            this.$emit('removeProfile');
            this.$emit('updateModal', true)
        },
        getOffsetTop() {
            if (document.getElementById('start-content')){
                const top = document.getElementById('start-content').clientHeight + document.getElementById('start-content').offsetTop;
                if (top) {
                    this.top = top+'px';
                } else {
                    this.top = '200px'
                }
            }
        },
    },  
    mounted() {
        setTimeout(() => {
            this.getOffsetTop()
        }, 2000);
        window.addEventListener('resize', this.getOffsetTop);
    },
    unmounted() {
        window.removeEventListener('resize', this.getOffsetTop);
    },
    computed: {
        isDisable() {
            if (this.enable !== null & this.enable !== undefined) {
                return true
            }
            return false
        },
        start() {
            return this.state == 'idle' && !this.showQuiz && !this.showModal
        }
    },
}
</script>

<style scoped>
#get-vs-button {
    width: 480px;
    height: 240px;
    background: #333D47;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
    border-radius: 240px;
    color: white;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 0.231689px;
    padding: 0;
}
#get-vs-button:disabled{
    opacity: 0.5;
}
.btn-circle {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    padding: 10px 16px;
    font-size: 16px;
    text-align: center;
}
#get-vs-button-container {
    position: absolute;
    top: 11%;
    width: 75%;
}
.progress-circle {
    background-color: #FFFFFFB2;
    padding: 80px;
    border-radius: 36px;
}
@media (max-width: 2000px) {
    #get-vs-button {
        width: 160px;
        height: 77px;
        font-size: 24px;
        left: calc(50% - 80px);
        top: calc(v-bind(top) - 38.5px);
    }
    .progress-circle {
        padding: 20px;
    }
    #get-vs-button-container {
        width: 90%;
        top: 200px;
    }
}

@media (max-width: 1024px) {
    #get-vs-button-container {
        top: 160px;
    }
}
</style>