<template>
  <div
    class=""
    id="userProfileInput"
    v-if="!profileFilled && showModal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header px-3">
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          ></button>
          <div class="container">
            <div class="row">
              <div class="question">
                {{t('user_input.title')}}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="input-container">
            <!-- title -->

            <form v-on:submit="saveSettings">
              <!-- Gender -->
              <div class="row">
                <div class="col-6">
                  <label for="genderInput" class="form-label">
                    {{t('user_input.question')}}
                  </label>
                </div>
                <div class="col-6">
                  <select
                    class="form-select input select"
                    id="genderInput"
                    @change="checkDisabled"
                    v-model="this.info.gender"
                    onfocus="this.size=2;" 
                    onblur="this.size=1;" 
                    onchange="this.size=1; this.blur();" 
                  >
                    <option @change="checkDisabled" value="male" class="p-2">
                      {{ t("user_info.male") }}
                    </option>
                    <option @change="checkDisabled" value="female" class="p-2">
                      {{ t("user_info.female") }}
                    </option>
                  </select>
                </div>
              </div>
            </form>

          </div>
        </div>
        <button type="button" class="mx-auto btn continue-button" :disabled="disabled" @click="updateProfile">
          {{t('button.next')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "user-profile-input",
  props: {
    form: Object,
    showModal: Boolean
  },
  setup() {
    const { t, locale } = useI18n();
    const {profileFilled, updateProfileFilled} = inject('profileFilled');
    return { 
      profileFilled,
      updateProfileFilled,
      t, locale
    };
  },
  emits: ["updateProfile", "updateModal"],
  mounted() {
    setTimeout(() => {
      this.checkDisabled();
    }, 300);
  },
  components: {},
  computed: {
    age() {
      const age = []
      for (var i of Array(9).keys()) {
        if (i < 2) {
          continue;
        }
        else if ( i == 2) {
          age.push('18-29');
        } 
        else {
          age.push(`${i*10}-${i*10+9}`)
        }
      }
      age.push('90 or above')
      return age;
    }
  },
  methods: {
    loadForm() {
        this.info = this.form;
        if (this.debug) {
          this.info = {
            name: "DebugUser",
            email: "debug@panoptic.ai", 
            phoneNumber: "12345678",
            birthday: "2019-08-19",
            gender: "male",
            height: 170,
            weight: 70,
            unitSystem: false,
            countries: "Hong Kong",
            smoker: false,
            hypertension: false,
            bloodPressureMedication: false,
            diabetic: "No",
            heartDisease: false,
            depression: false,
          }
        }
    },
    checkDisabled() {
      let check = false;
      Object.keys(this.info).map((data) => {
        if (this.info[data] === null || this.info[data] === "") {
          check = true;
          return;
        }
      });
      this.disabled = check;
    },
    updateProfile() {
      this.$emit("updateProfile", this.info);
      this.closeModal();
    },
    closeModal() {
      this.$emit("updateModal", false);
    }
  },
  watch: {
    form(newValue) {
        this.info = newValue;
        this.checkDisabled();
    }
  },
  data() {
    return {
      info: {
        gender: "",
      },
      disabled: true, 
      debug: this.$root.debugMode
    };
  },
};
</script>

<style scoped>
#userProfileInput {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 20;
}
.modal {
  overflow: hidden;
  border-radius: 10px;
}
.modal-dialog {
  display: flex;
  top: 30%;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}
.modal-close-button {
  position: fixed;
  top: 4.5%;
  right: 3.5%;
}
/* Modal Content/Box */
.modal-content {
  position: grid;
  margin: 0% auto;
  padding: 5px;
  min-width: 100%;
  overflow: hidden;
  border-radius: 24px;
  padding: 0px;
  border: none;
  background-color: transparent;
}
.modal-header {
  display: flex;
  justify-content: center;
  border: none;
  background: rgba(165, 145, 92, 0.9);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  padding: 140px 0;

}
.modal-body {
  justify-content: center;
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.9);;
  opacity: 1;
  border-radius: 0px 0px 36px 36px;
}
.form-label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  letter-spacing: 0.25px;
  margin: 7px 0px;
  padding-bottom: 114px;
}
.continue-button {
  background: #333D47;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
  border-radius: 240px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  color: white;
  text-align: center;
  letter-spacing: 0.231689px;
  height: 240px;
  width: 480px;
  position: relative;
  top: -120px;
}
.btn-close {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 40px;
}
.content {
  /* border-style: dashed;
    border: 2px purple; */
  padding: 1%;
}
.content-title {
  font-weight: bolder;
  font-size: x-large;
}
.photo-div {
  /* border: 1px red;
    border-style: solid; */
  margin: 0 auto;
  padding: 10px;
}
.photo {
  max-width: 80%;
  margin: 0 auto;
}
.center-item {
  display: grid;
  justify-content: center;
}
.paragraph-div {
  /* border: 1px blue;
    border-style: solid; */
  text-align: justify;
  padding: 5px;
}
.paragraph-title {
  font-weight: bolder;
  font-size: x-large;
}
.paragraph-content {
  font-size: medium;
  width: 100%;
}
.modal-footer {
  bottom: 0px;
  height: 10%;
  justify-content: center;
  border: none;
  padding-top: 0;
}
.logo {
  width: 48px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
}
.title {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.25px;
    text-align: left;
    color: white;
}
.subtitle {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    opacity: 0.5;
}
.question {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  text-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 8px 28px rgba(0, 0, 0, 0.08);
}
.input {
  border: 3px solid black;
  border-radius: 240px;
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  font-size: 48px;
  font-weight: 700;
  background: transparent;
}
.input-container {
  padding: 110px 120px 116px 120px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.select-wrapper {
  height: auto !important;
  overflow-y: hidden;
}

.select {
  width: 100%;
  padding: 10px;
}

option:hover {
  background-color: lightgrey;
  border-radius: 50px;
}

option:checked {
  border-radius: 50px;
}

.form-select:focus {
  border-radius: 50px;
}

@media (max-width: 1800px) {
  .modal-dialog {
    max-width: 80%;
  }
  .form-label {
    font-size: 24px;
    padding-bottom: 30px;
  }
  .continue-button {
    font-size: 24px;
    width: 215px;
    height: 77px;
    top: -38.5px;
  }
  .title {
    font-size: 16px;
  }
  .subtitle {
    font-size: 12px;
  }
  .question {
    font-size: 24px;
  }
  .form-select {
    text-align: center;
  }
  select {
    padding-left: 0;
    padding-right: 0;
    direction: ltr;
  }
  .modal-header {
    padding: 60px 0;
  }
  .btn-close {
    font-size: 20px;
    top: 20px;
  }
  .input-container {
    padding: 20px 50px 40px 50px;
  }
  .input {
    font-size: 24px;
  }
  .modal-body {
    height: fit-content;
  }
  .form-select:focus {
    border-radius: 20px;
    overflow: hidden;
  }

}
</style>


