<template>
    
    <div class="color-gray justify-content-center" :style="{'padding' : '40px 75px'}">
        <div class="d-flex justify-content-between">
            <div class="description" v-if="type !== 'personal_health_goal'" >
                <div class="d-flex justify-content-end">
                    <div class="col-2 align-self-start">
                        <img :src="require(`./assets/${vital}.png`)" alt="" class="pe-0 float-end icon-vital-signs">
                    </div>
                    <div class="col ps-5">
                        <div class="d-flex border-green fw-bolder" :style="{'font-size': '45px'}">{{t('health_report.' + type + '.' + vital + '.title')}}</div>
                        <div class="d-flex text-justify" :style="{'font-size': '40px'}">
                            <p class="text-justify px-0">{{t('health_report.' + type + '.' + vital + '.definition')}}</p>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <div class="ps-5 col-10 align-self-start">
                        <div class="d-flex border-green fw-bolder" :style="{'font-size': '40px'}">
                            {{t('health_report.measured')}}
                        </div>
                        <div class="d-flex text-justify" :style="{'font-size': '40px'}">
                            <p class="text-justify px-0">{{t('health_report.' + type + '.' + vital + '.measured')}}</p>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end my-3">
                    <div class="col-10 ps-5">
                        <span class="row text-justify"  :style="{'font-size': '40px'}">
                            <div class="col-1">
                                <img src="./assets/lightbulb.png" alt="" class="icon-facts float-end">
                            </div>
                            <div class="col" v-html="t('health_report.' + type + '.' + vital + '.range')">
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="float-start h-100 pe-5">
                    <img :src="require(`./assets/${vital}.png`)" alt="" class="icon-vital-signs">
                </div>
                <div class="text-justify definition">
                    <p class="border-green fw-bolder" :style="{'font-size': '45px'}">{{t('health_report.' + type + '.' + vital + '.title')}}</p>
                    <p :style="{'font-size': '40px'}" class="text-justify px-0">{{t('health_report.' + type + '.' + vital + '.definition')}}</p>
                </div>
            </div>
            <div v-if="type !== 'personal_health_goal'" class="col-4 align-self-center">
                <div v-if="vital !== 'facialSkinAge'">
                    <div class="justify-content-center">
                        <chart :tag="vital" :dataRange="data[vital]['dataRange']" :min="data[vital]['min']" :max="data[vital]['max']" :unit="data[vital]['unit']" :data="this.getData(vital).toFixed(data[vital]['decimal'])" :pdf="true"/>
                    </div>
                    <div class="justify-content-center text-center" :style="{'font-size': '40px'}">
                        <p v-html="t('health_report.' + type + '.' + vital + '.result', {value: this.getData(vital).toFixed(data[vital]['decimal'])})">
                        </p>
                    </div>
                </div>
                <div v-else>
                    <div class="row justify-content-center age-number">
                        {{ skinAge }}
                    </div>
                    <div class="row justify-content-center text-center" :style="{'font-size': '40px'}">
                        <p>
                            {{ t('health_report.facial.facialSkinAge.result') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Chart from "./chart.vue";
import { useI18n } from 'vue-i18n';
export default {
    setup() {
        const { t, locale } = useI18n();
        return {
            t, locale
        }
    },
    props: {
        vital: String,
        info: Object,
        type: String,
    },
    components: {
        Chart
    },
    methods: {
        getData(tag) {
            if (this.info) {
                return this.info[tag];
            }
            else {
                return 0;
            }
        }
    },    
    computed: {
        stressValue() {
            if (this.info.stress < 2) {
                return this.t('graph.excellent');
            }
            else if (this.info.stress >= 2 && this.info.stress < 3) {
                return this.t('graph.good');
            } else {
                return this.t('graph.poor');
            }
        },
        skinAge() {
            if (this.info.facialSkinAge < 30) {
                return this.t('health_report.facial_skin.young')
            } else if ( this.info.facialSkinAge < 50) {
                return this.t('health_report.facial_skin.healthy')
            } else {
                return this.t('health_report.mature')
            }
        }
    },
    data() {
        return {
            data: {
                generalWellness: {
                    dataRange: 100,
                    min: 40,
                    max: 100,
                    unit: '',
                    decimal: 0
                },
                stress: {
                    dataRange: 5,
                    min: 0,
                    max: 3,
                    unit: '',
                    decimal: 1
                },
                facialSkinAge: {
                    decimal: 1,
                },
                spo2: {
                    dataRange: 100,
                    min: 95,
                    max: 100,
                    unit: '%',
                    decimal: 0
                },
                heartRate: {
                    dataRange: 220,
                    min: 42,
                    max: 83,
                    unit: ' bpm',
                    decimal: 1
                }
            }
        }
    }
}
</script>

<style scoped>
.description {
    flex: 0 0 auto;
    width: 64%;
}
.age-number {
    color: #A5915C;
    font-size: 120px;
}

.color-gray {
    color: #747579;
}

.icon-vital-signs {
    width: 200px;
}

.text-justify {
    text-align: justify;
}

.border-green {
    border-bottom:solid;
    border-width: 2px;
    border-bottom-color: #5B636B;
}

.definition {
    padding-left: 240px;
}
</style>