<template>
    <div class="pdf" v-if="Object.keys(info).length > 0">
        <div id="pdf" v-if="!download">
            <div>
                <img src="./assets/loading.gif" alt="" class="loading pb-3" >
                <p v-html="t('health_report.wait')"></p>
            </div>
        </div>
        <div id="pdf" v-else-if="download && pdf">
            <div>
                <img src="./assets/checkmark.gif" alt="" class="checkmark pb-3">
                <span v-html="t('health_report.finish[0]')"></span>
                <span class="view-report" @click="viewReport">{{t('health_report.finish[1]')}}</span>
                <span v-html="t('health_report.finish[2]')"></span>
            </div>
        </div>
        <div id="pdf-1" ref='pdf_1' >
            <div class="mb-5">
                <h1 class="fw-bolder color-gray-aia" :style="{'font-size': '80px'}">
                    {{t('health_report.title')}}
                </h1>
            </div>
            <div class="d-flex bg-light content-card fw-bold my-5 justify-content-center" :style="{'padding' : '50px 100px'}">
                <div class="col-5">
                    <div class="d-flex align-items-center">
                        <div class="col-4 p-0 w-100 user-info" style="color: #5B636B">
                            <div class="my-2">{{t('health_report.sex')}}: {{ t('health_report.'+info.gender)}}</div>
                        </div>
                    </div>
                </div>
                
                <div class="col-7">
                    <div class="row color-gray align-items-center h-100">
                        <div class="col-6">
                            <div class="general-score">
                                <div class="row justify-content-center text-center" :style="{'font-size': '50px'}">
                                    {{t('health_report.wellness.stress.title')}}                                
                                </div>
                                <div class="row justify-content-center mt-2 mb-3 color-brown"  :style="{'font-size': '80px'}">
                                    <img src="./assets/stress-top.png" alt="covid" class="ps-0 icon-profile-vitals" >
                                    {{this.getData('stress').toFixed(1) }}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="general-score">
                                <div class="row justify-content-center text-center" :style="{'font-size': '50px'}">
                                    {{t('health_report.general_wellness')}}
                                </div>
                                <div class="row justify-content-center mt-2 mb-3 color-brown"  :style="{'font-size': '80px'}">
                                    <img src="./assets/general-wellness-top.png" alt="covid" class="ps-0 icon-profile-vitals" :style="{'height': '150px', 'width': '150px'}">
                                    {{ this.getData('generalWellness').toFixed(1) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- wellness -->
            <vitals-type :info="info" :vitalSign="['generalWellness', 'stress']" :type="'wellness'"/>

            <!-- facial -->
            <vitals-type :info="info" :type="'facial'" :vitalSign="['facialSkinAge']"/>

            <div class="justify-content-center" :style="{'padding-top' : '40px'}">
                <div class="text-justify"  :style="{'font-size': '25px'}">
                    <p>{{t('health_report.disclaimer.title')}}:</p>
                    <p v-html="t('health_report.disclaimer.p1')"></p>
                    <p>{{t('health_report.disclaimer.p2')}}</p>
                </div>
            </div>
            <div class="row pt-4">
                <img src="./assets/panoptic.png" alt="" class="icon-vital-signs">
            </div>
        </div>

        <div id="pdf-2" ref="pdf_2">        
            <div class="mb-5">
                <h1 class="fw-bolder color-gray-aia" :style="{'font-size': '80px'}">
                    {{t('health_report.title')}}
                    <img src="./assets/lify.png" alt="" class="lify-logo float-end">
                </h1>
            </div>

            <div class="recommendation">
                <div class="rec">
                    <div class="rec-title">
                        <div class="rec-title-text ">
                            <p class="">
                                {{t('scan_result.lify_recommendation')}}
                            </p>
                        </div>
                    </div>
                    <div class="result-box row justify-content-center">
                        <div class="lify-rec col p-0" v-for="tea in info.lify" :key="tea">
                            <img :src="require(`../home/assets/${tea}-banner.png`)" alt="" class="w-100">            
                            <div class="top-content">
                                <img :src="require('./assets/'+tea+'.jpg')" alt="" class="lify-image float-end">
                                <div class="title d-inline">
                                    <span>{{t('health_report.recommend.' + tea + '.title')}}</span>
                                    <span :style="{'font-size': '45px'}" v-html="t('health_report.recommend.' + tea + '.subtitle')"></span>
                                </div>
                                <div class="subtitle m-0">
                                    {{te('health_report.recommend.' + tea + '.note') ? `(${t('health_report.recommend.' + tea + '.note')})` : ''}}<br v-if="te('health_report.recommend.' + tea + '.note')">
                                    {{t('health_report.recommend.' + tea + '.ingredients')}}
                                </div>
                                <div class="description">
                                    {{t('health_report.recommend.' + tea + '.description')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img :src="require('' + this.t('health_report.recommend.lify_banner'))" class="w-100 lify-banner">
            </div>

            <div class="row justify-content-center" :style="{'padding-top' : '40px'}">
                <div class="text-justify"  :style="{'font-size': '25px'}">
                    <p>{{t('health_report.disclaimer.title')}}:</p>
                    <p v-html="t('health_report.disclaimer.p1')"></p>
                    <p>{{t('health_report.disclaimer.p2')}}</p>
                </div>
            </div>
            <div class="row pt-4">
                <img src="./assets/panoptic.png" alt="" class="icon-vital-signs">
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { newUserManager } from "../../user/company-user-manager.ts";
import { useRoute, useRouter } from 'vue-router'
import CryptoJS from "crypto-js";
import VitalsType from "./vitals-type.vue"
import { jsPDF } from "jspdf";
import * as html2canvas from 'html2canvas'; 
// gain muscle
// http://localhost:8080/#/report?val=U2FsdGVkX1%2F5cGCIVwVpxtH9YGEm5fHvRsR1yPr7cXKlAh4aEKNA5d8JRtqEDshSZvy9dkSKGT9m%2F07Oruy2kEkNEn3Q9MdTG2tXPnHFsfNBJyqeaMd4C7oHlxqIuAMddtQtLHt6jDsau%2B2ezUyOyRrmqIiBOR5oW9qm1ELTNZNE%2FXdGBvgASr4FtjzViAMGm%2FQLKJN5KnvW0p4jDZBiAP5ZFJ%2BknMFZfAGww5KyEeS7AY7ojR2oUWoginejAGhclynyBLomLazgqXkCrMN43XTFZe4saZ1TMzB3Y3rzxqC43NXvn3T7j0pmJKLjKmxYTu2DFQ4ZYqU1ONAvHm6qkA%3D%3D
// lose weight
// http://localhost:8080/#/report?val=U2FsdGVkX19AK%2BaraOr1czAYqOIcEc%2FkmNHBPPfvvfuS3H3TvmWq7%2FpIMOkguAeK%2FwBhq0Wfd1Jb7jK4DSRJzpO%2BOKcyd%2FPCN8jKhc8o%2FiVVSU9%2BaKiCgXNPR%2FRYIBVtRAFrIxNRX86sHoNRMCkvjYvWRtW3arTS6WqbSOe1aKwDx6Uzo8fq71xeeEkJXCxmfsOq2WUJM6hUeK4xyn7yX6luu%2Bc3RlmcA6De0aTzhBpzE04GA7wjhCbYG6VZLvdVdJ7q9wapKIdZckMIv72%2FIPbxb%2FaJ8j5zU4IH6dB2r42dEQPx7mr62ZhWeFy8MaUP
export default {
    name: "report",
    setup() {
        const { t, te, locale } = useI18n();
        const route = useRoute();
        var info = {}
        if (Object.keys(route.query).length == 0) {
            const router = useRouter();
            router.push('/');
        } else {
            const query = decodeURIComponent(route.query.val);
            info = JSON.parse(CryptoJS.AES.decrypt(query,'AIA-TDA-2022').toString(CryptoJS.enc.Utf8))
        }

        var currentUser = newUserManager.currentUser;
        const {profileFilled } = inject('profileFilled');

        return {
            t, te, locale,
            currentUser,
            profileFilled,
            info
        }
    },
    components: {
        VitalsType
    },
    created() {
        if ( this.$root.$data.debugMode ) {
            // pass            
        } 
        if(this.info) {
            this.$i18n.locale = this.info.language;
        }
    },
    computed: {
        userName() {
            if (!this.currentUser) {
                return "";
            }
            if (this.currentUser.currentUser.name == 'Guest') {
                return this.t("user.guest")
            }
            return this.currentUser.currentUser.name;
        },
    },
    methods: {
        getData(tag) {
            if (this.info) {
                return this.info[tag];
            }
            else {
                return 0;
            }
        },
        viewReport() {
            this.pdf.save(`Health Report`);
        }
    },
    mounted() {
        this.timeout = setTimeout(() => {
            if (Object.keys(this.info).length == 0) {
                clearTimeout(this.timeout);
                return;
            }
            let pdfRef1 = this.$refs.pdf_1;
            let pdfRef2 = this.$refs.pdf_2;
            // let pdfRef3 = this.$refs.pdf_3;
            let pdf = new jsPDF('p', 'mm', '');

            pdf.setProperties({
                title: "Health Report",
                subject: 'PanopticAI x Lify x AIA TDA Health Report',
                author: 'PanopticAI',
                creator: 'PanopticAI'
            })

            html2canvas(pdfRef1, {
                    scale: 1,
                    allowTaint:true,
                    onclone: function (clonedDoc) {
                    clonedDoc.getElementById('pdf-1').style.display = 'inline-block';
                }
            }).then(canvas1 => {
                let pdfImage1 = canvas1.toDataURL('image/jpeg', 1.0);

                pdf.addImage(pdfImage1, 'JPEG', 0, 0, 210, 297, "alias1", 'SLOW');

                pdf.addPage();

                html2canvas(pdfRef2, {
                    scale: 1,
                    allowTaint:true,
                    onclone: function (clonedDoc) {
                        clonedDoc.getElementById('pdf-2').style.display = 'inline-block';
                    },
                    onload
                }).then(canvas => {
                    let pdfImage = canvas.toDataURL('image/jpeg', 1.0);

                    pdf.addImage(pdfImage, 'JPEG', 0, 0, 210, 297, "alias2", 'SLOW');

                    pdf.save(`Health Report`);
                    this.pdf = pdf;
                    this.download = true;
                })
            })
        }, 1500);
    },
    data() {
        return {
            timeout: undefined,
            download: false,
            pdf: undefined,
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,400;0,700;0,800;1,300&display=swap');

#pdf-1 {
    width: 2480px;
    height: 3508px;
    padding: 125px;
    background-color: #F5F5F5;
    transform: scale(0.5);
}
#pdf-2 {
    width: 2480px;
    height: 3508px;
    padding: 125px;
    background-color: #F5F5F5;
    transform: scale(0.5);
}
#pdf-3 {
    width: 2480px;
    height: 3508px;
    padding: 125px;
    background-color: #F5F5F5;
    transform: scale(0.5);
}

.pdf {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
#pdf {
    background-color: white;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.general-score {
    background-color: #EEEFF3;
    border-radius: 40px;
    padding: 5px;
    height: 100%;
}
.loading {
    width: 70px;
}
.color-brown {
    color: #A5915C;
}

.container-full {
  padding: 50px;
  height: 100vh;
}

.user-info {
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    color: #0EA080;
}

.border-gray {
    border-bottom:solid;
    border-width: 3px;
    border-bottom-color: #333D47;
}

.view-report {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
}

.content {
  position: static;
  padding-left: 87px;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: #e4e3e3;
  overflow-y: scroll;
  font-size: large;
}
.font-sarabun {
    font-family: 'Sarabun';
}

.icon {
    width: 70px;
    height: 70px;
    margin-right: 30px;
}

.icon-facts {
    width: 50px;
    height: 60px;
}

.icon-profile-vitals {
    width: 120px;
    height: 150px;
}

.icon-vital-signs {
    width: 500px;
    padding: 0px;
}
.content-card {
    border-radius: 60px;
}
#logo {
    float: right;
    height: 30px;
}

.color-gray {
    color: #747579;
}
.color-gray-aia {
    color: #333D47;
}
#profile-img {
  margin: auto auto;
  margin-bottom: 5px;
  max-width:60%;
}
.fittery-title {
    line-height: 150px;
}
.rec-title{ 
    display: flex;
    align-items: center;
    margin-bottom: 75px;
}
.rec-title-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 55px;
    font-weight: 500;
    line-height: 132%;
    letter-spacing: 0.1971px;
    color: #12121F;
}
.rec-title-text .subtitle {
    font-style: italic;
    font-size: 36px;
}

.rec-logo {
    float: left;
    margin-right: 36px;
}

.lify-logo {
    width: 250px;
}

.lify-rec {
    padding-bottom: 30px;
    border-radius: 60px;
    background: white;
}

.text-justify {
    text-align: justify;
}
li {
    list-style: none;
}
li:before {
    font-family: 'Montserrat';
    content: "✔";
    padding-right: 20px;
}
.result-box {
    /* box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 8px 28px rgba(0, 0, 0, 0.08); */
    padding: 0;
    gap: 50px;
}

.result-box .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 50px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1971px;
    padding-bottom: 24px;
}

.result-box .subtitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 37px;
    line-height: 45px;
    letter-spacing: 0.1971px;
    padding-bottom: 24px;
    white-space: pre-wrap; 
}

.result-box .description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    letter-spacing: 0.1971px;
    color: #333D47;
    padding-bottom: 20px;
}
.top-content {
    padding: 80px;
    padding-top: 40px;
}
.top-content .title {
    font-size: 55px;
    line-height: 70px;
}
.lify-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 50px;
}
.fittery-image {
    width: 104px;
    height: 104px;
    object-fit: cover;
    border-radius: 50%;
}
.checkmark {
    width: 100px;
}
.lify-banner {
    border-radius: 60px;
    margin-top: 100px;
}
@media (max-width: 768px) {
  .content {
    padding-left: 0px;
    margin-bottom: 50px;
  }
}
</style>