
<template>
  <!-- Console -->
  <div class="debug-console">
    <!-- Title -->
    <div class="debug-console-title">
        {{ title }}
    </div>
    <div class="debug-console-suptitle">
        Dev Console
    </div>

    <!-- Content -->
    <div class="debug-console-content">
        {{ debugMessage }}
    </div>

    <!-- Guide -->
    <div class="debug-console-guide">
        This is debug console. You can toggle the debug mode in [main.vue]. 
    </div>
  </div>
</template>

<script>

export default {
  name: "debug-console",
  mounted() {
    console.log("%cDebug Console is enabled.", "color: grey")
  },
  computed: {
    title() {
        return `${this.$root.appTitle}`
    }, 
    debugMessage() {
        /* You construct your debug message here */
        let debugMsg = this.$root.debugMessage;
        return debugMsg
    }
  },
};
</script>

<style scoped>
.debug-console {
  position: absolute;
  right: 0px;
  bottom: 20%;
  min-width: 300px;
  max-width: 300px;
  min-height: 400px;
  padding: 10px;
  text-align: justify;
  font-size: medium;
  color: rgba(29, 253, 66, 0.856);
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  z-index: 10;
  opacity: 0.1;
  display: none;
}
.debug-console-title {
    color: rgba(252, 62, 62, 0.925);
    text-decoration: solid underline;
    font-weight: bold;
    font-size: x-large;
    text-align: left;
}
.debug-console-suptitle{
    color: rgba(253, 182, 182, 0.8);
    /* text-decoration: solid underline; */
    font-style: italic;
    font-size: small;
    text-align: left;
}
.debug-console-content {
    font-size: large;
}
.debug-console-guide {
    font-size: medium;
    color: rgb(136, 136, 136);
}
@media (max-width: 768px) {
  .debug-console {
    position: absolute;
    right: 0px;
    bottom: 30%;
    min-width: 180px;
    max-width: 180px;
    min-height: 200px;
    padding: 10px;
    text-align: justify;
    font-size: medium;
    color: rgba(29, 253, 66, 0.856);
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
  }
  .debug-console-title {
    color: rgba(252, 62, 62, 0.925);
    text-decoration: solid underline;
    font-weight: bold;
    font-size: large;
  }
  .debug-console-guide {
    font-size: small;
  }
}
</style>