export class Camera {
  constructor(
    video,
    options) {
    this.video = video;
    this.onFrame = options.onFrame;
    if (options.width) {
      this.width = options.width;
    }
    if (options.height) {
      this.height = options.height;
    }
    if (options.deviceId) {
      this.deviceId = options.deviceId;
    }

    this.requestAnimation = () => {
      const requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame;
      this.animation = requestAnimationFrame(this.callback)
    }

    this.callback = () => {
      this.onFrame();
      this.requestAnimation();
    }
  }

  async waitCameraToStop() {
    return new Promise((resolve, reject) => {
      setInterval(() => {
        try {
          const track = this.video.srcObject.getVideoTracks();
        }
        catch (e) {
          resolve();
        }
      }, 1000)
    })
  }

  async start() {
    this.video.srcObject = await navigator.mediaDevices.getUserMedia({
      video: {
        width: { ideal: this.width },
        height: { ideal: this.height },
        frameRate: { ideal: 30 },
        deviceId: this.deviceId
      },
    });
    this.requestAnimation();
  }

  async stop() {
    if (this.video) {
      this.video.srcObject.getVideoTracks().forEach(track => { track.enabled = false; track.stop(); });
      this.video.srcObject = undefined;
    }
    const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
    cancelAnimationFrame(this.animation)
  }

}