<template>
  <div class="start-content p-0">
    <div v-if="proAccount">
      <div v-if="!showRecommendation">
        <div class="header w-100 m-0">
          <button type="button" class="back-button btn text-dark" @click="newScan">
            &#60; {{ t("button.back_to_homepage") }}
          </button>
          <p class="title m-0">
            {{ t("scan_result.title") }}
          </p>

          <div class="row question mx-auto">
            <div class="col text-center question-item p-0">
              <img src="../assets/facial-skin-age.svg" alt="" class="result-logo" />
              <p class="vital-sign">{{ t("scan_result.skin_age") }}</p>
              <p class="vital-sign-value">{{ skinAge }}</p>
            </div>
            <div class="col text-center question-item p-0">
              <img src="../assets/report.svg" alt="" class="result-logo" />
              <p class="vital-sign">{{ t("scan_result.wellness") }}</p>
              <p class="vital-sign-value">{{ generalWellness }}%</p>
            </div>
            <div class="col text-center question-item p-0">
              <img src="../assets/stress.svg" alt="" class="result-logo" />
              <p class="vital-sign">{{ t("scan_result.stress") }}</p>
              <p class="vital-sign-value">{{ stressValue }}</p>
            </div>
          </div>
          <!-- <div class="row">
                            <p class="subtitle text-decoration-underline" @click="updateShowRecommendation">
                                {{t('scan_result.skip')}}
                            </p>
                        </div> -->
        </div>
      </div>
      <div v-else-if="showRecommendation && !showQr">
        <div class="header w-100 m-0">
          <p class="title-recommendation m-0 mt-2" v-html="t('scan_result.email')"></p>
        </div>

        <div class="description text-start">
          <form v-on:submit="saveSettings">
            <!-- Email -->
            <input
              type="text"
              class="form-control input"
              id="emailInput"
              v-model="this.email"
              :placeholder="t('scan_result.email_placeholder')"
              v-on:keyup.enter="enterMethod"
              @input="checkDisabled"
            />
          </form>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button v-if="!showQr" type="button" class="btn continue-button mx-3" :disabled="disabled" @click="submitEmail">
          {{ t("button.submit") }}
        </button>
        <button v-if="!showQr && !showRecommendation" type="button" class="btn continue-button mx-3" @click="newScan">
          {{ t("button.start_again") }}
        </button>
      </div>
    </div>
    <div v-else>
      <div class="header error w-100 m-0">
        <p class="title fs-2 m-0">
          Your pro account subscription is expired <br />
          Please contact PanopticAI
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <button type="button" class="btn continue-button" @click="newScan">
          {{ t("button.start_again") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { newUserManager } from "../../../user/company-user-manager";
export default {
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  emits: ["updateShowRecommendation", "updateShowQr", "removeProfile"],
  props: {
    vitalSign: Object,
    showRecommendation: Boolean,
    showQr: Boolean,
    scanResultId: String,
  },
  computed: {
    stressValue() {
      if (!this.vitalSign.stress) {
        return "-";
      }
      if (this.vitalSign.stress < 2) {
        return this.t("health_report.stress.low");
      } else if (this.vitalSign.stress >= 2 && this.vitalSign.stress < 3) {
        return this.t("health_report.stress.medium");
      } else {
        return this.t("health_report.stress.high");
      }
    },
    skinAge() {
      if (this.vitalSign.facialSkinAge < 30) {
        return this.t("health_report.facial_skin.excellent");
      } else if (this.vitalSign.facialSkinAge < 50) {
        return this.t("health_report.facial_skin.fair");
      } else {
        return this.t("health_report.need_improvement");
      }
    },
    generalWellness() {
      if (newUserManager.currentUser.UID == "03592310-dce1-41ed-b67f-3b680387eeb3") {
        if (this.vitalSign.generalWellness && this.vitalSign.generalWellness < 70) {
          return 70;
        }
      }
      if (this.vitalSign.generalWellness) {
        return this.vitalSign.generalWellness.toFixed(0);
      } else {
        return "-";
      }
    },
    proAccount() {
      if (newUserManager.currentUser.companySettings.planType == "pro") {
        if (
          newUserManager.currentUser.companySettings.planExpiryDate !== "unlimited" &&
          new Date(newUserManager.currentUser.companySettings.planExpiryDate) < new Date()
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    back() {
      this.$emit("updateShowRecommendation", false);
    },
    newScan() {
      this.back();
      this.$emit("removeProfile");
    },
    async submitEmail() {
      this.$emit("updateShowQr", true);
      this.updateShowRecommendation();

      await newUserManager.currentUser.updateUserEmail(this.scanResultId, this.email);
      window.scrollTo(0, 0);
    },
    updateShowRecommendation() {
      this.$emit("updateShowRecommendation", true);
    },
    enterMethod(e) {
      e.preventDefault();
      if (this.disabled) {
        alert("Email is invalid");
      } else {
        this.submitEmail();
      }
    },
  },
  data() {
    return {
      email: "",
      disabled: true,
    };
  },
};
</script>

<style scoped>
.back-button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.231689px;
  color: #ffffff !important;
  padding-bottom: 50px;
}
.input {
  border: 2px solid #333d47;
  filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.2));
  border-radius: 240px;
  background-color: transparent;
  height: 81px;
  margin-bottom: 30px;
  font-family: "AIA Everest";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  padding: 0 30px;
}
.continue-button {
  background: #333d47;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
  border-radius: 240px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  color: white;
  text-align: center;
  letter-spacing: 0.231689px;
  height: 240px;
  width: 480px;
  position: relative;
  top: -120px;
}
.description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  letter-spacing: 0.25px;
  color: #333d47;
  padding: 80px 123px 120px 123px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0px 0px 36px 36px;
  position: relative;
}
.general-wellness {
  font-size: 40px;
  padding-top: 80px;
}
.general-wellness-shadow {
  position: relative;
  transform: translate(-50px, -10px);
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  line-height: 350px;
  font-size: 400px;
  letter-spacing: 0.25px;
  color: #ffffff;
  width: 100%;
  text-align: center;
}
.header {
  background-color: rgba(165, 145, 92, 0.9);
  border-radius: 24px 24px 0px 0px;
  padding: 120px 80px;
}
.error {
  border-radius: 24px;
}
.number {
  display: inline-block;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #333d47;
  width: 90px;
  height: 90px;
  border: 1px solid #333d47;
  border-radius: 56px;
  margin: 0 40px 15px 40px;
}
.opacity {
  opacity: 0.9;
}
.progress {
  background-color: #333d47;
  border-radius: 66px;
  width: 404px;
}
.qr-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #5699c1;
  padding-top: 18px;
}
.question-item {
  flex: 1 1 0;
  width: 0;
  word-wrap: break-word;
}
.question {
  padding-top: 60px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}
.result-logo {
  height: 80px;
  width: 80px;
}
.start-content-container {
  position: absolute;
  top: 20%;
  left: 10%;
  width: 80%;
}
.start-content {
  max-height: 80vh;
  overflow: scroll;
}

.subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  padding-top: 80px;
}
.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 68px;
  text-align: center;
  letter-spacing: 0.25px;
  color: white;
}
.vital-sign {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 59px;
  letter-spacing: 0.25px;
  color: #ffffff;
  padding: 24px 0;
}
.vital-sign-value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 108px;
  line-height: 100px;
  letter-spacing: 0.25px;
  color: #ffffff;
  text-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 8px 28px rgba(0, 0, 0, 0.08);
}
.title-recommendation {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
}
@media (max-width: 2000px) {
  .back-button {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .continue-button {
    font-size: 24px;
    height: 90px;
    width: 200px;
    top: -45px;
  }
  .description {
    font-size: 24px;
    padding: 40px;
  }
  .general-wellness {
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .general-wellness-shadow {
    font-size: 250px;
    line-height: 220px;
    transform: translate(-40px, -10px);
  }
  .general-wellness-value {
    font-size: 250px;
    line-height: 220px;
    transform: translate(5px, 5px);
  }
  .header {
    padding: 30px 40px 50px 40px;
  }
  .number {
    font-size: 24px;
    line-height: 45px;
    height: 45px;
    width: 45px;
  }
  .progress {
    min-width: 129px;
    width: 250px;
    height: 0.7rem;
  }
  .qr-text {
    font-size: 16px;
    width: 150px;
  }
  .question {
    padding-top: 30px;
    gap: 10px;
  }
  .result-logo {
    width: 64px;
    height: 64px;
  }
  .steps {
    font-size: 16px;
  }
  .start-content-container {
    width: 90%;
    left: 6.5%;
    top: 150px;
  }
  .stress-value {
    font-size: 36px;
    line-height: 40px;
  }
  .subtitle {
    font-size: 20px;
    padding-top: 48px;
    margin: 0;
  }
  .title {
    font-size: 32px;
  }
  .vital-sign {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0;
  }
  .vital-sign-value {
    font-size: 40px;
    line-height: 43px;
  }
}

@media (max-width: 940px) {
  .start-content-container {
    top: 120px;
  }
}
</style>
